import { Context } from '../bookingsMachine';
import * as api from '@/api/bookings';

const filterBookings = async (ctx: Context, evt) => {
    const res = await api.fetchBookings({
        CompanyBookings: evt.payload.CompanyId ? true : false,
        CompanyId: evt.payload.CompanyId,
        ...evt.payload,
    });

    return res;
};

export default filterBookings;
