import { useEffect } from 'react';
import { useInterpret } from '@xstate/react';
import localforage from 'localforage';
import { StateFrom, AnyStateMachine } from 'xstate';

function createPersistRehidrate<T extends AnyStateMachine>(storageKey: string, _machine: T) {
    return ({ state }: { state: StateFrom<typeof _machine> }) => {
        const service = useInterpret(
            _machine,
            { state: state },
            ({
                machine,
                configuration,
                toStrings,
                toJSON,
                matches,
                can,
                hasTag,
                history,
                transitions,
                ...restState
            }) => localforage.setItem(storageKey, restState)
        );

        useEffect(() => {
            service.start();

            return () => {
                service.stop();
            };
        }, []);

        return { service };
    };
}

export { createPersistRehidrate };
