import { Context } from '../bookingsMachine';
import * as api from '@/api/bookings';
import { TAKE_ITEMS } from '@/env/environment';

const fetchBookings = async (ctx: Context, evt) => {
    const skip = ctx.Results.length === 0 && ctx.Offset === 0 ? 0 : ctx.Offset + TAKE_ITEMS;

    const { Total, Results, Offset, ...params } = ctx;
    const res = await api.fetchBookings({
        Skip: skip,
        ...params,
    });

    return res;
};

export default fetchBookings;
