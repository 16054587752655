/** @jsxImportSource theme-ui */
import { Text, Flex, Container, Heading, Box, get } from 'theme-ui';
import styled from '@emotion/styled';
import React from 'react';

interface Props {
    count?: number;
    rowCount?: number;
    heading?: boolean;
    columnCount?: number;
}

const SpinnerStyled = styled.div<{ height: string }>`
    margin-top: -${({ theme }) => get(theme, 'radii.sm')};
    .loader.row {
        width: 100%;
    }
    .loader.row:first-of-type {
        .skeleton {
            border-top-left-radius: ${(props) => get(props, 'style.borderRadius')};
            border-top-right-radius: ${(props) => get(props, 'style.borderRadius')};
        }
    }
    .loader {
        .skeleton {
            background: ${({ theme }) => get(theme, 'colors.background')};
            border: 1px solid;
            border-color: ${({ theme }) => get(theme, 'colors.shadow')} transparent
                ${({ theme }) => get(theme, 'colors.shadow')} transparent;
            border-radius: ${(props) => get(props, 'style.borderRadius')};
            -webkit-border-radius: ${(props) => get(props, 'style.borderRadius')};
            margin-top: ${(props) => (get(props, 'style.borderRadius') ? '10px 15px' : '-1px')};
            margin: ${(props) => get(props, 'style.margin')};

            &.skeleton--card {
                min-width: 500px;
                display: inline-block;
                vertical-align: text-top;
            }

            .skeleton--content {
                height: ${(props) => get(props, 'height')};
                position: relative;

                .loader {
                    border-radius: ${({ theme }) => get(theme, 'radii.sm')};
                    background: ${({ theme }) => get(theme, 'colors.gray.1')};
                    -webkit-animation-duration: 1s;
                    -webkit-animation-fill-mode: forwards;
                    -webkit-animation-iteration-count: infinite;
                    -webkit-animation-name: placeholderSkeleton;
                    -webkit-animation-timing-function: linear;
                    background-image: -webkit-gradient(
                        linear,
                        left center,
                        right center,
                        ${({ theme }) => get(theme, 'colors.gray.1')},
                        color-stop(0.2, ${({ theme }) => get(theme, 'colors.gray.2')}),
                        color-stop(0.4, ${({ theme }) => get(theme, 'colors.gray.1')}),
                        to(${({ theme }) => get(theme, 'colors.gray.1')})
                    );
                    background-image: -webkit-linear-gradient(
                        left,
                        ${({ theme }) => get(theme, 'colors.gray.1')} 0%,
                        ${({ theme }) => get(theme, 'colors.gray.2')} 20%,
                        ${({ theme }) => get(theme, 'colors.gray.1')} 40%,
                        ${({ theme }) => get(theme, 'colors.gray.1')} 100%
                    );
                    background-repeat: no-repeat;
                    background-size: 800px 104px;
                    height: 104px;
                    position: relative;
                }

                .skeleton--content-wrapper {
                }

                .skeleton--table {
                    .skeleton--tr {
                        display: flex;
                        .skeleton--th {
                            flex: 1 1 100%;
                            height: 15px;
                            margin: 10px 10px 15px;
                        }
                        .skeleton--td {
                            flex: 1 1 100%;
                            height: 10px;
                            margin: 5px 10px;
                        }

                        .skeleton--td__2 {
                            flex-basis: 300%;
                        }
                        .skeleton--td__3 {
                            flex-basis: 500%;
                        }
                        .skeleton--td__4 {
                            flex-basis: 700%;
                        }
                        .skeleton--td__5 {
                            flex-basis: 900%;
                        }
                    }
                }

                .skeleton--title {
                    margin: 5px 10px;
                    height: 20px;
                    width: 200px;
                }

                .skeleton--hr {
                    height: 2px;
                    width: calc(~'100% - 20px');
                    margin: 0 10px 10px;
                }

                .skeleton--line {
                    height: 10px;
                    width: calc(~'100% - 20px');
                    margin: 10px;

                    &.skeleton--line__short {
                        width: 120px;
                    }
                }

                .skeleton--circle {
                    margin: 5px 10px 10px;
                    height: 60px;
                    width: 60px;
                    border-radius: 10px;
                }

                .fl {
                    display: inline-block;
                    width: auto;
                    vertical-align: text-top;
                }
            }
        }
    }

    @-webkit-keyframes placeholderSkeleton {
        0% {
            background-position: -468px 0;
        }
        100% {
            background-position: 468px 0;
        }
    }
`;

const SkeletonLoader: React.FC<Props> = ({
    count = 3,
    rowCount = 1,
    columnCount = 3,
    heading = false,
    ...props
}) => {
    const height = (heading ? 40 : 0) + rowCount * 1.5 * 24;

    return (
        <SpinnerStyled height={`${height}px`} {...props}>
            <Box sx={{ borderRadius: 'sm', overflow: 'hidden' }}>
                {Array.from(Array(count).keys()).map((_, i) => (
                    <div key={i} className="loader row">
                        <div className="skeleton">
                            <div className="skeleton--content">
                                {heading ? (
                                    <div className="skeleton--content-wrapper">
                                        <div className="loader skeleton--title"></div>
                                        <div className="loader skeleton--hr"></div>
                                    </div>
                                ) : null}
                                <div className="skeleton--content-wrapper skeleton--table">
                                    {Array.from(Array(rowCount).keys()).map((_, i) => (
                                        <div key={i} className="skeleton--tr">
                                            {Array.from(Array(columnCount).keys()).map((_, i) => (
                                                <React.Fragment key={i}>
                                                    <div className="loader skeleton--th"></div>
                                                    <div
                                                        className={`loader skeleton--th skeleton--td__${i}`}
                                                    ></div>
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Box>
        </SpinnerStyled>
    );
};

export default SkeletonLoader;
