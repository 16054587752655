/** @jsxImportSource theme-ui */
import { Text, ThemeUIStyleObject, Box, get, Flex } from 'theme-ui';
import React from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Home } from 'tabler-icons-react';

interface Props extends React.LinkHTMLAttributes<HTMLLinkElement> {
    to?: string;
    label: React.ReactElement | string;
    sx?: ThemeUIStyleObject;
    icon: React.ReactElement[];
    isNavLinkAware?: boolean;
    collapsed?: boolean;
}

const CustomLink: React.FC<Props> = ({
    children,
    to,
    label,
    icon,
    isNavLinkAware = true,
    collapsed = false,
    sx = {},
    ...rest
}) => {
    const theme = useTheme();
    const match = useMatch(to ?? '');
    const extendedMatch = match && match?.pathname !== '/';

    const Wrapper = to ? NavLink : Flex;

    return (
        // @ts-ignore
        <Wrapper
            to={to}
            sx={{
                borderRadius: 'xsm',
                mb: 3,
                textDecoration: 'none',
                fontFamily: get(theme, 'links.default.fontFamily'),
                display: 'flex',
                alignItems: 'center',
                justifyContent: collapsed ? 'center' : undefined,
                backgroundColor:
                    isNavLinkAware && extendedMatch
                        ? get(theme, 'colors.primary')
                        : get(theme, 'colors.background'),
                color:
                    isNavLinkAware && extendedMatch
                        ? get(theme, 'colors.background')
                        : get(theme, 'colors.gray.9'),

                ...sx,
            }}
            {...rest}
        >
            <Flex sx={{ justifyContent: 'center', svg: { margin: 0 } }}>
                {React.Children.map(icon, (child, i) => {
                    if (i > 0 && extendedMatch) {
                        return child;
                    } else if (i === 0 && !extendedMatch) {
                        return child;
                    }
                })}
            </Flex>
            {!collapsed ? <Box as="span">{label}</Box> : null}
        </Wrapper>
    );
};

export default CustomLink;
