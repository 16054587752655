import fetchSupportCasesAttachment from '@/api/fetchSupportCasesAttachment';
import { Context } from '../supportCaseMachine';

const fetchComments = async (ctx: Context) => {
    const res = await fetchSupportCasesAttachment({ id: ctx.case.Id });

    return res;
};

export default fetchComments;
