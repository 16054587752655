import { assign } from 'xstate';
import { Context } from '../filtersMachine';

const changeOrderDirection = assign<Context, any>({
    filters: ({ filters }, evt) => {
        return filters.map((filter) => {
            if (filter.type === 'OrderBy') {
                const _filter = { ...filter };
                _filter.operator = _filter.operator === 'ascending' ? 'descending' : 'ascending';
                return _filter;
            }

            return filter;
        });
    },
});

export default changeOrderDirection;
