import showError from './showError';
import assignSuccessData from './assignSuccessData';
import assignLoadingData from './assignLoadingData';
import assignArraySuccessData from './assignArraySuccessData';
import assignErrorData from './assignErrorData';

export default {
    showError,
    assignSuccessData,
    assignLoadingData,
    assignArraySuccessData,
    assignErrorData,
};
