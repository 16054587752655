import { createMachine } from 'xstate';
import { nanoid } from 'nanoid';
import { Filter, SearchParams } from '@/containers/companies/types';
import actions from './actions';
import services from './services';

export interface Context extends SearchParams {
    filters: Filter<any>[];
}

// Actions
export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const CHANGE_ORDER_DIRECTION = 'CHANGE_ORDER_DIRECTION';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const ADD_FILTERS = 'ADD_FILTERS';

// Other constants
export const FILTERS_STORAGE_KEY = 'bm-saui-companies-filters';

export default createMachine<Context>({
    initial: 'ready',
    id: 'filtersMachine',
    context: {
        filters: [
            { id: nanoid(), operator: '', type: 'Search', value: '', criteria: '' },
            {
                id: nanoid(),
                operator: 'ascending',
                type: 'OrderBy',
                value: 'Created',
                criteria: '',
            },
        ],
        Search: '',
        OrderBy: '',
        Categories: [],
        IsEmail: '',
        IsNotEmail: '',
    },
    states: {
        ready: {
            entry: ['generateParams'],
            on: {
                [CHANGE_ORDER_DIRECTION]: {
                    target: ['ready'],
                    actions: ['changeOrderDirection'],
                },
                [ADD_FILTER]: {
                    target: ['addingFilter'],
                    actions: ['updateLocation'],
                },
                [ADD_FILTERS]: {
                    target: 'ready',
                    actions: ['addFilters'],
                },
                [REMOVE_FILTER]: {
                    target: 'ready',
                    actions: ['removeFilter', 'updateLocation'],
                },
                [UPDATE_FILTER]: {
                    target: 'ready',
                    actions: ['updateFilter', 'updateLocation'],
                },
                [CLEAR_FILTERS]: {
                    target: 'ready',
                    actions: ['clearFilters'],
                },
            },
        },
        addingFilter: {
            invoke: {
                src: 'fetchCriteriaOptions',
                onDone: {
                    target: 'ready',
                    actions: ['addFilter', 'generateParams'],
                },
                onError: {
                    target: 'error',
                },
            },
        },
        error: {},
    },
}).withConfig({
    services,
    actions,
});
