import keycloak, { REFRESH_TOKEN_INTERVAL } from '@/keycloak';
import axios from 'axios';
import { toast } from 'react-toastify';
import { setupCache, defaultKeyGenerator, defaultHeaderInterpreter } from 'axios-cache-interceptor';
import { get } from 'theme-ui';
import { theme } from '../theme';
import localforage from 'localforage';
import { AUTH_STORAGE_KEY } from '@/providers/AuthenticationProvider';

const client = setupCache(
    // axios instance
    axios.create({
        // window is used in runtime, process.env in tests
        baseURL: process.env.REACT_APP_API_BASE_URL,
        responseType: 'json',
    }),

    // All options with their default values
    {
        // The storage to save the cache data. There are more available by default.
        //
        // https://axios-cache-interceptor.js.org/#/pages/storages
        // storage: buildMemoryStorage(),

        methods: ['get'],

        // The mechanism to generate a unique key for each request.
        //
        // https://axios-cache-interceptor.js.org/#/pages/request-id
        generateKey: defaultKeyGenerator,

        staleIfError: false,

        // The mechanism to interpret headers (when cache.interpretHeader is true).
        //
        // https://axios-cache-interceptor.js.org/#/pages/global-configuration?id=headerinterpreter
        headerInterpreter: defaultHeaderInterpreter,

        // The function that will receive debug information.
        // NOTE: For this to work, you need to enable development mode.
        //
        // https://axios-cache-interceptor.js.org/#/pages/development-mode
        // https://axios-cache-interceptor.js.org/#/pages/global-configuration?id=debug
        // debug: undefined,

        // ttl: 1 * 60 * 1000,
        ttl: 1000,
    }
);

client.interceptors.request.use(
    (config) => {
        config.headers['Accept'] = 'application/json';
        config.headers['x-api-key'] = process.env.REACT_APP_API_KEY;
        config.headers['x-language'] = process.env.REACT_APP_LANGUAGE;

        return config;
    },
    (err: Error | string) => {
        const errorMessage = typeof err === 'string' ? err : err.message;
        toast(errorMessage, {
            bodyStyle: {
                color: get(theme, 'colors.danger'),
            },
        });
        return err;
    }
);

client.interceptors.request.use(async (config) => {
    const { sessionId, token } = await localforage.getItem<{
        sessionId: string;
        token: string;
        refreshToken: string;
    }>(AUTH_STORAGE_KEY);
    config.headers['x-ss-id'] = sessionId;
    config.headers['Authorization'] = 'Bearer ' + token;

    return config;
});

client.interceptors.request.use(async (config) => {
    const isExpired = keycloak.isTokenExpired(REFRESH_TOKEN_INTERVAL);
    if (isExpired) {
        return keycloak.updateToken(REFRESH_TOKEN_INTERVAL).then((refreshed: boolean) => {
            if (refreshed) {
                if (refreshed) {
                    config.headers['x-ss-id'] = keycloak.sessionId;
                    config.headers['Authorization'] = 'Bearer ' + keycloak.token;
                }
                return config;
            } else {
                throw new Error('Token not refreshed');
            }
        });
    }

    return config;
});

export default client;
