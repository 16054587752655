import { assign } from 'xstate';
import { nanoid } from 'nanoid';
import { AnyFilterOperator, Filter } from '@/containers/companies/types';
import { columnKeys } from '@/containers/companies/columnKeys';
import { Context } from '../filtersMachine';
import { filters } from '../const';

const addFilters = assign<Context, any>({
    filters: (ctx, evt) => {
        const payload = evt.payload || evt.data.payload || [];

        return payload;
    },
});

export default addFilters;
