import client from '@/api/client';
import { definitions } from '@/apitypes';
import { TAKE_ITEMS } from '@/env/environment';

export const fetchCompanies = (params: definitions['CompanyQuery'] = {}) => {
    const defaultParams: definitions['CompanyQuery'] = {
        Active: true,
        Take: TAKE_ITEMS,
        Skip: 0,
    };

    return client
        .get<definitions['QueryResponse_CompanyQueryResponse_']>('/superadmin/companies', {
            params: {
                ...defaultParams,
                ...params,
            },
        })
        .then((res) => res.data);
};
