/** @jsxImportSource theme-ui */
import { CompanyBooking, NestedKey } from '@/types/types';
import { Table } from '@component-controls/components';
import { formattedFromTo } from '@/utils/datetime';

interface TableColumn<T extends Record<string, unknown>> {
    columnKey?: string;
    Cell?: ({ cell }: { cell: { row: { original: T } } }) => React.ReactElement | string;
    Header?: string;
    width?: string;
    accessor: NestedKey<T>;
}
export const CompanyBookings: React.FC<{ bookings: CompanyBooking[] }> = ({ bookings }) => {
    const columns: TableColumn<CompanyBooking>[] = [
        {
            Header: 'Id',
            accessor: 'Id',
            width: '50px',
        },
        {
            Header: 'Service',
            accessor: 'Service.Description',
        },
        {
            Header: 'Time',
            accessor: 'From',
            Cell: ({ cell }) => {
                const fromTo = formattedFromTo(cell.row.original.From, cell.row.original.To);

                return fromTo;
            },
        },
    ];

    return <Table columns={columns} data={bookings} />;
};
