import { Flex, Link, Text } from 'theme-ui';
import React from 'react';
import { ColumnAction } from './columns';

interface Props {
    actions?: ColumnAction[];
}

const ActionsItems: React.FC<Props> = (props) => {
    return (
        <Flex
            sx={{
                flexDirection: 'column',
                gap: 3,
            }}
        >
            {props.actions.map((action, i) => (
                <Link
                    key={i}
                    href="#"
                    variant="menu"
                    target="_blank"
                    onClick={(evt) => {
                        evt.preventDefault();
                        action.onClick();
                    }}
                >
                    {/* @ts-ignore */}
                    {action.icon ? <action.icon width={16} height={16} /> : null}
                    <Text>{action.label}</Text>
                </Link>
            ))}
        </Flex>
    );
};

export default ActionsItems;
