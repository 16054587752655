import fetchCompany from './fetchCompany';
import fetchCompanyAdministrators from './fetchCompanyAdministrators';
import fetchCompanyLicenses from './fetchCompanyLicenses';
import fetchCompanyInvoices from './fetchCompanyInvoices';

export default {
    fetchCompany,
    fetchCompanyAdministrators,
    fetchCompanyLicenses,
    fetchCompanyInvoices,
};
