import client from '@/api/client';
import { definitions } from '@/apitypes';
import { TAKE_ITEMS } from '@/env/environment';
import { Context } from '../companyInfo';

type CompanyQuery = definitions['CompanyQuery'];

interface FetchCompaniesParams extends CompanyQuery {}

const fetchCompany = (params: Context, evt) => {
    const { isFetching, data, error, ...desiredParams } = params;

    const payloadParams = evt.payload && Object.keys(evt.payload).length > 0 ? evt.payload : {};

    return client
        .get<definitions['QueryResponse_CompanyQueryResponse_']>('/superadmin/companies', {
            params: {
                ...payloadParams,
                ...desiredParams,
            },
        })
        .then((res) => res.data);
};

export default fetchCompany;
