import fetchSupportCasesComment from '@/api/fetchSupportCasesComment';
import { definitions, operations, paths } from '@/apitypes';
import { Context } from '../supportCaseMachine';

const fetchComments = async (ctx: Context) => {
    const res = await fetchSupportCasesComment({ id: ctx.case.Id });

    return res;
};

export default fetchComments;
