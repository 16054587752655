/** @jsxImportSource theme-ui */
import { Flex, Heading, Box, Text, Grid } from 'theme-ui';
import Button from '@/components/Button';
import { useDialogState, Dialog, DialogBackdrop } from 'reakit/Dialog';

interface ConfirmatioModalProps {
    onYes?: () => void;
    onNo?: () => void;
}

export const ConfirmationModal: React.FC<ConfirmatioModalProps> = ({
    onNo = () => {},
    onYes = () => {},
    ...restProps
}) => {
    const dialog = useDialogState({ visible: true, modal: true });

    return (
        <DialogBackdrop {...dialog}>
            <Dialog
                hideOnClickOutside={false}
                sx={{
                    '&&': {
                        top: 'calc(50% - 100px)',
                        padding: 4,
                    },
                }}
                {...dialog}
            >
                {(props) => (
                    <Grid {...props}>
                        <Heading>Are you sure?</Heading>
                        <Text>
                            This action can not be reversed, all company data will be permanetelly
                            deleted!!
                        </Text>
                        <Flex sx={{ gap: 2, justifyContent: 'center' }}>
                            <Button onClick={onNo} variant="secondary">
                                No
                            </Button>
                            <Button onClick={onYes} variant="danger">
                                Yes
                            </Button>
                        </Flex>
                    </Grid>
                )}
            </Dialog>
        </DialogBackdrop>
    );
};
