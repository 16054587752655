import { Context } from '../bookingMachine';
import deleteBooking, { DeleteBookingsParams } from '@/api/deleteBooking';

const cancelBooking = (
    ctx: Context,
    evt: { payload: { id: string; params: DeleteBookingsParams } }
) => {
    return deleteBooking(evt.payload.id, evt.payload.params);
};

export default cancelBooking;
