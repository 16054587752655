import { pick, omit } from 'lodash';
import { Context } from '../companiesMachine';
import * as api from '@/api/companies';

const filterCompanies = async (ctx: Context, evt) => {
    const payload = evt.payload || omit(ctx, ['Results', 'Total', 'Offset']);
    const res = await api.fetchCompanies({ ...payload });

    return res;
};

export default filterCompanies;
