export const filters = [
    {
        id: 'BookedResourceIds',
        type: 'Multiple',
        format: '',
    },
    {
        id: 'BookedResourceTypeIds',
        type: 'Multiple',
        format: '',
    },
    {
        id: 'CompanyId',
        type: 'Single',
        format: '',
    },
    {
        id: 'Id',
        type: 'Single',
        format: 'int32',
    },
    {
        id: 'CancellationCode',
        type: 'Single',
        format: '',
    },
    {
        id: 'BookingStart',
        type: 'Single',
        format: 'date-time',
    },
    {
        id: 'BookingEnd',
        type: 'Single',
        format: 'date-time',
    },
    {
        id: 'CreatedFrom',
        type: 'Single',
        format: 'date-time',
    },
    {
        id: 'CreatedTo',
        type: 'Single',
        format: 'date-time',
    },
    {
        id: 'ServiceIds',
        type: 'Multiple',
        format: '',
    },
    {
        id: 'StatusIds',
        type: 'Multiple',
        format: '',
    },
    {
        id: 'CustomerId',
        type: 'Single',
        format: '',
    },
];
