/** @jsxImportSource theme-ui */
import { Badge, Text, Flex, ThemeUIStyleObject } from 'theme-ui';
import { ChevronDownIcon, ChevronUpIcon, FilterIcon } from '@heroicons/react/solid';
// TODO: extract type information
import { Filter } from '@/containers/bookings/types';
import { useEffect, useState } from 'react';
import { FontSize } from '@/theme/utils';

interface Props {
    count: number;
    opened: boolean;
    sx?: ThemeUIStyleObject;
    onToggle: () => void;
}

const BadgeSelect: React.FC<Props> = ({ count, onToggle, opened, ...restProps }) => {
    return (
        <Flex
            onClick={onToggle}
            {...restProps}
            bg="background"
            sx={{
                borderRadius: 'sm',
                alignItems: 'center',
                height: '100%',
                p: 2,
                gap: 2,
                fontSize: FontSize.sm,
                cursor: 'pointer',
            }}
        >
            {count === 0 ? (
                <FilterIcon sx={{ color: 'gray.7', width: 4, height: 4 }} />
            ) : (
                // @ts-ignore
                <Badge>{count}</Badge>
            )}
            <Text>Filter</Text>
            {opened ? (
                <ChevronDownIcon width={16} height={16} />
            ) : (
                <ChevronUpIcon width={16} height={16} />
            )}
        </Flex>
    );
};

export default BadgeSelect;
