import { Context } from '../filtersMachine';
import qs from 'qs';

const updateLocation = (ctx: Context, evt) => {
    const filtersStringified = qs.stringify({
        filters: qs.stringify(ctx.filters),
    });

    const url = new URL(window.location.href);
    url.hash = `${url.hash.split('?')[0]}?${filtersStringified}`;

    window.location.href = url.href;
};

export default updateLocation;
