/** @jsxImportSource theme-ui */
import { Grid, Flex } from 'theme-ui';
import CompanyInfo from './CompanyInfo';
import CompanyAdministrators from './CompanyAdministrators';
import CompanyLicenses from './CompanyLicenses';
import CompanyInvoices from './CompanyInvoices';

const CompanyDetails = () => {
    return (
        <Grid>
            <Flex
                sx={{
                    gap: 4,
                    flexDirection: 'column',
                }}
            >
                <CompanyInfo />
                <CompanyAdministrators />
                <CompanyLicenses />
                <CompanyInvoices />
            </Flex>
        </Grid>
    );
};

export default CompanyDetails;
