import client from '@/api/client';
import { definitions } from '@/apitypes';
import { Context } from '../companyAdministrators';

const fetchCompanyAdministrators = (params: Context, evt) => {
    const { isFetching, data, error, ...desiredParams } = params;
    const payloadParams = evt.payload && Object.keys(evt.payload).length > 0 ? evt.payload : {};

    const defaultParams = {
        IncludeComments: true,
    };

    return client
        .get<definitions['CompanyUserQueryResponse']>('/superadmin/administrators', {
            id: `fetch-company-administrators-${payloadParams.CompanyId}`,
            params: {
                ...defaultParams,
                ...desiredParams,
                ...payloadParams,
            },

            cache: {
                interpretHeader: true,
                ttl: 0.5 * 60 * 1000,
            },
        })
        .then((res) => res.data);
};

export default fetchCompanyAdministrators;
