import { assign } from 'xstate';
import { nanoid } from 'nanoid';
import { AnyFilterOperator, Filter } from '@/containers/companies/types';
import { columnKeys } from '@/containers/companies/columnKeys';
import { Context } from '../filtersMachine';
import { filters } from '../const';

const addFilter = assign<Context, any>({
    filters: (ctx, evt) => {
        const payload = evt.payload || evt.data.payload;

        let operator: AnyFilterOperator = '';
        let value: string | any[] = '';

        if (payload.type === 'OrderBy') {
            operator = 'ascending';
            value = columnKeys[0];
        } else if (payload.type === 'Multiple' || payload.type === 'Single') {
            operator = 'Is';
        }

        return [
            ...ctx.filters,
            {
                id: nanoid(),
                operator,
                ...payload,
                format: filters.find((f) => f.id === payload.criteria)?.format,
                value,
            },
        ];
    },
});

export default addFilter;
