import { Criteria, FilterOperator, FilterType, OrderByOperator } from './types';

export const getOperators = (filterType: FilterType): { value: string; label: string }[] => {
    let filterOperators = [];

    if (filterType === 'OrderBy') {
        filterOperators = [
            {
                value: 'ascending',
                label: 'Ascending',
            },
            {
                value: 'descending',
                label: 'Descending',
            },
        ] as { value: OrderByOperator; label: string }[];
    } else if (filterType === 'Multiple' || filterType === 'Single') {
        filterOperators = [
            {
                value: 'Is',
                label: 'Is',
            },
            {
                value: 'IsNot',
                label: 'Is not',
            },
        ] as { value: FilterOperator; label: string }[];
    }

    return filterOperators;
};

export const getFilterTypeByCriteria = (criteria: Criteria): FilterType => {
    if (criteria === 'StatusIds') {
        return 'Multiple';
    }

    return 'Single';
};
