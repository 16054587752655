import { Routes, Route, Link, Navigate } from 'react-router-dom';

const ProtectedRoute = ({ authenticated, children }) => {
    if (!authenticated) {
        return null;
    }

    return children;
};

export default ProtectedRoute;
