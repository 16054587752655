import { useMemo, useCallback } from 'react';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';

const useQuery = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const queryParams: any = useMemo(() => {
        const parsed = qs.parse(location.search.slice(1));
        Object.keys(parsed).forEach((key) => {
            if (parsed[key] === '') {
                delete parsed[key];
            }
        });
        return parsed;
    }, [location.search]);

    const change = useCallback((newParams: any = {}) => {
        const notEmptyParams = Object.keys(newParams).reduce((acc, key) => {
            if (newParams[key] === '' || newParams[key] === null || newParams[key] === undefined) {
                return acc;
            }
            return { ...acc, [key]: newParams[key] };
        }, {});
        navigate({ search: qs.stringify(notEmptyParams) });
    }, []);

    return { queryParams, change };
};

export default useQuery;
