import { assign } from 'xstate';
import { Context } from '../supportCaseMachine';

const assignCase = assign({
    case: (ctx: Context, evt: any) => {
        return evt?.payload;
    },
});

export default assignCase;
