/** @jsxImportSource theme-ui */
import { Heading, Box, Container, Text } from 'theme-ui';
import {
    Company,
    CompanyAdministrator,
    CompanyBooking,
    CompanyInvoice,
    CompanyLicense,
} from '@/types/types';
import { CompanyDetails } from './CompanyDetails';
import { CompanyAdministrators } from './CompanyAdministrators';
import { CompanyBookings } from './CompanyBookings';
import CompanyLicenses from './CompanyLicenses';
import CompanyInvoices from './CompanyInvoices';

export const CompanyOverview: React.FC<{
    company: Company;
    companyBookings: CompanyBooking[];
    companyAdministrators: CompanyAdministrator[];
    companyLicenses: CompanyLicense[];
    companyInvoices: CompanyInvoice[];
}> = ({ company, companyBookings, companyAdministrators, companyLicenses, companyInvoices }) => (
    <>
        <Box sx={{ overflow: 'scroll' }}>
            <Container variant="segment">
                <CompanyDetails company={company} />
            </Container>
        </Box>
        <Box
            sx={{
                overflow: 'scroll',
            }}
        >
            <Box mb="4">
                <Heading mb={2} as="h3">
                    Bookings(top 100):
                </Heading>
                {companyBookings.length > 0 ? (
                    <>
                        <CompanyBookings bookings={companyBookings} />
                    </>
                ) : (
                    <Text>No bookings found.</Text>
                )}
            </Box>

            <Box mb="4">
                <Heading mb={2} as="h3">
                    Administrators:
                </Heading>
                {companyAdministrators.length > 0 ? (
                    <>
                        <CompanyAdministrators administrators={companyAdministrators} />
                    </>
                ) : (
                    <Text>No administrators found.</Text>
                )}
            </Box>

            <Box mb="4">
                <Heading as="h3">Licenses:</Heading>
                {companyLicenses.length > 0 ? (
                    <>
                        <CompanyLicenses companyLicenses={companyLicenses} />
                    </>
                ) : (
                    <Text>No licenses found.</Text>
                )}
            </Box>

            <Box mb="4">
                <Heading as="h3">Invoices:</Heading>
                {companyInvoices.length > 0 ? (
                    <>
                        <CompanyInvoices companyInvoices={companyInvoices} />
                    </>
                ) : (
                    <Text>No invoices found.</Text>
                )}
            </Box>
        </Box>
    </>
);
