/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';
import { Button, Text, Badge, Box, Checkbox, Flex, get } from 'theme-ui';
import Select from './Select';
import {
    Props as ReactSelectProps,
    components,
    GroupBase,
    OptionProps,
    InputAction,
} from 'react-select';
import { sortBy } from 'lodash';
import { ThemeUIStyleObject } from 'theme-ui';

interface Props extends ReactSelectProps {
    /**
     * when this is set to true, dropdown will be opened and focused
     */
    open?: boolean;
    value?: { value: string; label: string }[];
    sx?: ThemeUIStyleObject;
}

export const DropdownSelect: React.FC<Props> = ({
    children,
    onChange,
    open = false,
    value: valueProp = [],
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(true);
    const [value, setValue] = useState<{ value: string; label: string }[]>([]);
    const toggleOpen = () => setIsOpen(!isOpen);
    const [input, setInput] = useState('');

    const sortedOptions = sortBy(props.options, (option) =>
        // @ts-ignore
        value.map((f) => f.value).includes(option.value) ? 0 : 1
    );

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        if (valueProp.length > 0) {
            setValue(valueProp);
        }
    }, [valueProp.length]);

    return (
        <Dropdown
            {...props}
            sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                fontSize: '12px',
            }}
            isOpen={isOpen}
            onClose={toggleOpen}
            target={
                <Flex sx={{ flexGrow: 1, height: '100%' }}>
                    <Button
                        sx={{
                            width: '100%',
                            background: 'gray.1',
                            borderRadius: 'sm',
                            gap: 1,
                            flexWrap: 'wrap',
                        }}
                        variant="plain"
                        onClick={toggleOpen}
                    >
                        {value.length > 0
                            ? value.slice(0, isOpen ? value.length : 1).map((val, i) => (
                                  <Flex key={i} sx={{ gap: 1 }}>
                                      <Badge>{val.label}</Badge>
                                      {value.length > 1 && !isOpen ? (
                                          <React.Fragment>
                                              <Text>and</Text>
                                              <Text
                                                  title={`${value
                                                      .slice(1)
                                                      .map((v) => v.label)
                                                      .join(', ')} (click to change)`}
                                              >
                                                  {value.length - 1} other
                                              </Text>
                                          </React.Fragment>
                                      ) : null}
                                  </Flex>
                              ))
                            : 'Select criteria'}
                    </Button>
                </Flex>
            }
        >
            <Select
                sx={{
                    width: '100%',
                    '.react-select__control': {
                        width: (t) => `calc(100% - 8px )`,
                        margin: '0 auto',
                        top: '2px',
                        background: 'gray.1',
                        zIndex: 3,
                    },
                    '.react-select__option--is-selected': {
                        backgroundColor: 'transparent',
                    },
                    '.react-select__menu': {
                        paddingTop: '30px',
                        marginTop: '-30px',
                    },
                }}
                autoFocus
                backspaceRemovesValue={false}
                components={{
                    IndicatorSeparator: null,
                    Option: SelectOption,
                    DownChevron: null,
                    DropdownIndicator: null,
                }}
                controlShouldRenderValue={false}
                isClearable={false}
                inputValue={input}
                onInputChange={(value, { action }) => {
                    if (action === 'menu-close') {
                        toggleOpen();
                        setInput('');
                    } else if (action === 'input-change') {
                        setInput(value);
                    }
                }}
                closeMenuOnSelect={false}
                menuIsOpen
                onChange={(value: any) => {
                    setValue(value);
                    // @ts-ignore
                    onChange(value);
                }}
                placeholder="Select criteria"
                value={value}
                {...props}
                options={sortedOptions}
            />
        </Dropdown>
    );
};

// styled components
const SelectOption: React.FC<
    React.PropsWithChildren<OptionProps<unknown, boolean, GroupBase<unknown>>>
> = (props) => {
    return (
        <components.Option
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
            {...props}
        >
            <Box>{props.children}</Box>

            <Box>
                <Checkbox onChange={() => {}} checked={props.isSelected} />
            </Box>
        </components.Option>
    );
};

const Menu = (props) => {
    const shadow = 'hsla(218, 50%, 10%, 0.1)';
    return (
        <div
            css={{
                borderRadius: 4,
                boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,

                position: 'absolute',
                zIndex: 2,
                left: 0,
                right: 0,
                bottom: '-40px',
            }}
            {...props}
        />
    );
};
const Blanket = (props) => (
    <div
        css={{
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            position: 'fixed',
            zIndex: 1,
        }}
        {...props}
    />
);
const Dropdown = ({ children, isOpen, target, onClose, ...props }) => (
    <Box sx={{ position: 'relative', ...props.sx }} {...props}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
        {isOpen ? <Blanket onClick={onClose} /> : null}
    </Box>
);

export default DropdownSelect;
