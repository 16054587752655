import { Criteria, FilterOperator, FilterType, OrderByOperator } from './types';

export const getOperators = (filterType: FilterType): { value: string; label: string }[] => {
    let filterOperators = [];

    if (filterType === 'OrderBy') {
        filterOperators = [
            {
                value: 'ascending',
                label: 'Ascending',
            },
            {
                value: 'descending',
                label: 'Descending',
            },
        ] as { value: OrderByOperator; label: string }[];
    } else if (filterType === 'Multiple' || filterType === 'Single') {
        filterOperators = [
            {
                value: 'Is',
                label: 'Is',
            },
            {
                value: 'IsNot',
                label: 'Is not',
            },
        ] as { value: FilterOperator; label: string }[];
    }

    return filterOperators;
};

export const getFilterTypeByCriteria = (criteria: Criteria): FilterType => {
    if (criteria === 'Categories') {
        return 'Multiple';
    }

    return 'Single';
};

type StatusValue =
    | 'Registered'
    | 'Awaiting approval'
    | 'Approved'
    | 'Inactive'
    | 'Closed'
    | 'Not approved';

interface StatusOption {
    id: number;
    value: StatusValue;
    color: string;
}

export const getCompanyStatusOption = (id: number): StatusOption => {
    switch (id) {
        case 1:
            return {
                id,
                value: 'Registered',
                color: 'orange.4',
            };
        case 2:
            return {
                id,
                value: 'Awaiting approval',
                color: 'warning',
            };
        case 3:
            return {
                id,
                value: 'Approved',
                color: 'green.5',
            };
        case 4:
            return {
                id,
                value: 'Inactive',
                color: 'muted',
            };
        case 5:
            return {
                id,
                value: 'Closed',
                color: 'muted',
            };
        case 6:
            return {
                id,
                value: 'Not approved',
                color: 'danger',
            };
        default:
            break;
    }
};
