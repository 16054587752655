/** @jsxImportSource theme-ui */
import { Table } from '@component-controls/components';
import { format } from 'date-fns';
import { CompanyLicense } from '@/types/types';

interface Props {
    companyLicenses: CompanyLicense[];
}

const CompanyLicenses: React.FC<Props> = ({ companyLicenses }) => {
    return (
        <Table
            columns={[
                { columnKey: 'TypeId', accessor: 'Id', Header: 'Type ID' },
                { columnKey: 'Name', accessor: 'Type.Name', Header: 'Name' },
                {
                    columnKey: 'Description',
                    accessor: 'Type.Description',
                    Header: 'Description',
                },
                {
                    columnKey: 'PeriodOfNotice',
                    accessor: 'Type.PeriodOfNoticeDays',
                    Header: 'Period of notice (days)',
                },
                {
                    columnKey: 'IsExtraLicense',
                    accessor: 'Type.IsExtraLicense',
                    Header: 'Is extra license',
                    Cell: ({ row }) => (row.original.Type.IsExtraLicense ? 'Yes' : 'No'),
                },
                {
                    columnKey: 'ValidFrom',
                    accessor: 'ValidFrom',
                    Header: 'Valid from',
                    Cell: ({ cell }) => format(new Date(cell.value), 'd MMM yyyy, p'),
                },
                {
                    columnKey: 'ValidTo',
                    accessor: 'ValidTo',
                    Header: 'Valid to',
                    Cell: ({ cell }) => format(new Date(cell.value), 'd MMM yyyy, p'),
                },
            ]}
            data={companyLicenses}
        />
    );
};

export default CompanyLicenses;
