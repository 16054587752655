/** @jsxImportSource theme-ui */
import { CompanyAdministrator } from '@/types/types';
import { Table } from '@component-controls/components';

export const CompanyAdministrators: React.FC<{ administrators: CompanyAdministrator[] }> = ({
    administrators,
}) => {
    return (
        <Table
            columns={[
                {
                    Header: 'Id',
                    accessor: 'Id',
                },
                {
                    Header: 'Firstname',
                    accessor: 'Firstname',
                },
                {
                    Header: 'Lastname',
                    accessor: 'Lastname',
                },
                {
                    Header: 'Email',
                    accessor: 'Email',
                },
            ]}
            data={administrators}
        />
    );
};
