import client from '@/api/client';
import { definitions } from '@/apitypes';

export const fetchCategories = (params: definitions['CategoryQuery'] = {}) => {
    const defaultParams: definitions['CategoryQuery'] = {};

    return client
        .get<definitions['QueryResponse_CategoryQueryResponse_']>('/categories', {
            params: {
                ...defaultParams,
                ...params,
            },
        })
        .then((res) => res.data);
};
