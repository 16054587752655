import { Box, get, Theme, ThemeUIStyleObject } from 'theme-ui';
import ReactSelect, { Options, Props as SelectProps } from 'react-select';

interface Props extends SelectProps {
    sx?: ThemeUIStyleObject;
}

const Select: React.FC<Props> = (props) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flex: 1,
                fontFamily: 'body',
                fontSize: '12px',
                background: 'background',
                borderRadius: 'sm',
                svg: {
                    width: 4,
                    height: 4,
                },
                '.react-select__input-container': {
                    py: 0,
                    my: 0,
                },
                '.react-select-container': {
                    width: '100%',
                    height: '28px',
                    p: 0,
                },
                '.react-select__control': {
                    background: 'gray.1',
                    borderRadius: 'xsm',
                    height: '28px',
                    border: '0px solid transparent',
                    fontWeight: 500,
                    minHeight: 'auto',
                    color: 'gray.5',
                    ...(props.sx && props.sx['.react-select__control']
                        ? props.sx['.react-select__control']
                        : {}),
                },
                '.react-select__placeholder': {
                    color: 'gray.7',
                },
                '.react-select__option': {
                    color: 'gray.7',
                },
                '.react-select__indicator': {
                    py: 0,
                    color: 'gray.7',
                },
                '.react-select__option--is-focused': {
                    backgroundColor: 'gray.1',
                },
                '.react-select__option--is-selected': {
                    backgroundColor: 'gray.2',
                },
                '.react-select__indicator-separator': {
                    visibility: 'hidden',
                },
                ...props.sx,
            }}
        >
            <ReactSelect
                classNamePrefix="react-select"
                className="react-select-container"
                // defaultMenuIsOpen={true}
                {...props}
            />
        </Box>
    );
};

export default Select;
