import { get } from 'lodash';

export enum FontSize {
    'sm' = 0,
    'base' = 1,
    'lg' = 2,
    'xl' = 3,
    '2xl' = 4,
}

export const getTailwindFontSizes = (
    typographyPreset,
    fontSizes = ['xs', 'sm', 'tiny', 'base', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl', '7xl']
) => {
    const tailwindTypography = get(typographyPreset, 'config.theme.typography', {});

    return Object.keys(tailwindTypography)
        .filter((key) => fontSizes.includes(key))
        .map((key) => tailwindTypography[key])
        .map((typography) => typography.css[0].fontSize);
};
