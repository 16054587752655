/** @jsxImportSource theme-ui */
import { Flex, Heading, Box, Spinner, Grid, Container, Label, Checkbox } from 'theme-ui';
import Button from '@/components/Button';
import React, { useEffect, useState } from 'react';
import { useDialogState, Dialog, DialogBackdrop } from 'reakit/Dialog';
import {
    useDeleteCompanyMutation,
    useGetCompanyAdministratorsQuery,
    useGetCompanyBookingsQuery,
    useGetCompanyInvoicesQuery,
    useGetCompanyLicensesQuery,
    useGetCompanyQuery,
} from '@/store/apiService';
import { setCompanyId } from '@/store/deleteCompany';
import { useDispatch, useSelector } from 'react-redux';
import Error from '@/components/Error';
import { ConfirmationModal } from './ConfirmationModal';
import { SuccessModal } from './SuccessModal';
import { CompanyOverview } from './CompanyOverview';

interface Props {}

const DeleteCompanyModal: React.FC<Props> = (props) => {
    const companyId = useSelector((s: any) => s.deleteCompany.companyId);
    const confirmedForDeletion = useSelector((s: any) => s.deleteCompany.confirmed);
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
    const [iContacted, setIcontacted] = useState(false);
    const dispatch = useDispatch();
    const dialog = useDialogState();
    const company = useGetCompanyQuery({ Id: companyId }, { skip: !companyId });
    const [deleteCompany, deleteCompanyState] = useDeleteCompanyMutation();
    const bookings = useGetCompanyBookingsQuery(
        { CompanyId: companyId, Take: 100 },
        { skip: !companyId }
    );
    const companyLicenses = useGetCompanyLicensesQuery(
        { CompanyId: companyId },
        { skip: !companyId }
    );

    const companyInvoices = useGetCompanyInvoicesQuery(
        { CompanyId: companyId },
        { skip: !companyId }
    );

    const administrators = useGetCompanyAdministratorsQuery({ CompanyId: companyId });

    useEffect(() => {
        if (companyId && !dialog.visible) {
            dispatch(setCompanyId());
        }
    }, [dialog.visible]);

    useEffect(() => {
        if (companyId) {
            dialog.show();
        }
    }, [companyId]);

    useEffect(() => {
        if (!companyId) {
            deleteCompanyState.reset();
        }
    }, [companyId]);

    const companyLoaded =
        !company.isFetching &&
        !bookings.isFetching &&
        !administrators.isFetching &&
        company.isSuccess &&
        bookings.isSuccess &&
        administrators.isSuccess;

    const companyLoading =
        company.isFetching ||
        bookings.isFetching ||
        administrators.isFetching ||
        company.isLoading ||
        bookings.isLoading ||
        administrators.isLoading;

    return (
        <>
            <DialogBackdrop {...dialog}>
                {companyLoaded && !deleteCompanyState.isSuccess ? (
                    <Dialog
                        sx={{
                            '&&': {
                                padding: 4,
                                pb: '70px',
                                minHeight: '480px',
                                overflow: 'scroll',
                            },
                        }}
                        {...dialog}
                    >
                        {confirmationModalVisible ? (
                            <ConfirmationModal
                                onNo={() => {
                                    setConfirmationModalVisible(false);
                                }}
                                onYes={() => {
                                    deleteCompany({ companyId, gotApprovedByAdmin: iContacted });
                                    setConfirmationModalVisible(false);
                                }}
                            />
                        ) : null}

                        <Grid
                            pb="3"
                            sx={{
                                height: '80vh',
                                overflowY: 'scroll',
                                gridTemplateRows: 'auto auto auto auto auto',
                                gap: 3,
                            }}
                        >
                            {/* Heading */}
                            <Heading>You're about to delete the following company</Heading>

                            <CompanyOverview
                                company={company.data}
                                companyBookings={bookings.data?.Results}
                                companyAdministrators={administrators.data?.Results}
                                companyLicenses={companyLicenses.data}
                                companyInvoices={companyInvoices.data.Results}
                            />

                            {/* Checkbox  */}
                            <Box mt="3">
                                <Label
                                    sx={{
                                        cursor: 'pointer',
                                        fontSize: 3,
                                        color: 'primary',
                                    }}
                                >
                                    <Checkbox
                                        sx={{ cursor: 'pointer' }}
                                        name="iContacted"
                                        type="checkbox"
                                        onChange={(evt) => {
                                            setIcontacted(evt.target.checked);
                                        }}
                                    />
                                    <Box ml={2}>
                                        Have you contacted the administrator and got a written
                                        verification by email they want to have their account
                                        deleted, email should be saved with Gmail label
                                    </Box>
                                </Label>
                            </Box>

                            {/* Error */}
                            {deleteCompanyState.isError ? (
                                <Box mb="3">
                                    <Container variant="error-message">
                                        <Error error={deleteCompanyState.error as any} />
                                    </Container>
                                </Box>
                            ) : null}

                            {/* Buttons */}
                            <Box sx={{ position: 'fixed', left: 4, right: 4, bottom: 4 }}>
                                <Flex sx={{ gap: 3, justifyContent: 'flex-end' }}>
                                    <Button variant="secondary" onClick={dialog.hide}>
                                        Cancel
                                    </Button>
                                    <Button
                                        disabled={!iContacted}
                                        isLoading={deleteCompanyState.isLoading}
                                        variant="danger"
                                        onClick={() => {
                                            setConfirmationModalVisible(true);
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </Flex>
                            </Box>
                        </Grid>
                    </Dialog>
                ) : null}
                {companyLoading ? (
                    <Dialog
                        {...dialog}
                        sx={{
                            '&&': {
                                padding: 4,
                            },
                        }}
                    >
                        <Spinner />
                    </Dialog>
                ) : null}
                {deleteCompanyState.isSuccess ? <SuccessModal onOk={dialog.hide} /> : null}
            </DialogBackdrop>
        </>
    );
};

export default DeleteCompanyModal;
