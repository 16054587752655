/** @jsxImportSource theme-ui */
import { get, Divider, Link } from 'theme-ui';
import { Box, Container, Button } from 'theme-ui';
import { useLocation, useMatch } from 'react-router-dom';
import { useActor, useSelector } from '@xstate/react';
import { ArrowBarLeft, ArrowBarRight } from 'tabler-icons-react';
import CustomLink from '@/components/Link';
import { useUiService } from '@/providers/UIProvider';
import { getNavState, NAV_TOGGLE } from '@/machines/uiMachine';
import { getIconForRoute } from '@/utils/routesIcons';
import User from '../user/User';

const SideNav: React.FC = ({ children }) => {
    const uiService = useUiService();
    const isSubnavExpanded = useSelector(uiService, getNavState);
    const collapsed = !isSubnavExpanded;

    const toggleCollapse = () => uiService.send(NAV_TOGGLE);

    const [HomeIcon, HomeIconSolid] = getIconForRoute('/');
    const [OfficeBuildingIcon, OfficeBuildingIconSolid] = getIconForRoute('/companies');
    const [CalendarIcon, CalendarIconSolid] = getIconForRoute('/bookings');
    const [UsersIcon, UsersIconSolid] = getIconForRoute('/users');
    const [TicketIcon, TicketIconSolid] = getIconForRoute('/tickets');
    const [CashIcon, CashIconSolid] = getIconForRoute('/invoicing');

    return (
        <Container
            bg="background"
            p="2"
            sx={{
                width: collapsed
                    ? (theme) => get(theme, 'sizes.navCollapsed')
                    : (theme) => get(theme, 'sizes.navExpanded'),
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                minHeight: '100vh',
                fontSize: '14px',
            }}
        >
            <Box sx={{ position: 'sticky', top: 0 }}>
                <CustomLink
                    collapsed={collapsed}
                    // icon={<Home size={20} sx={{ mr: collapsed ? 0 : 2, p: 2 }} />}

                    icon={[
                        <HomeIcon width={24} height={24} sx={{ mr: collapsed ? 0 : 2, p: 2 }} />,
                        <HomeIconSolid
                            width={24}
                            height={24}
                            sx={{ mr: collapsed ? 0 : 2, p: 2 }}
                        />,
                    ]}
                    to="/"
                    label="Home"
                />
                <CustomLink
                    collapsed={collapsed}
                    icon={[
                        <OfficeBuildingIcon
                            width={24}
                            height={24}
                            sx={{ mr: collapsed ? 0 : 2, p: 2 }}
                        />,
                        <OfficeBuildingIconSolid
                            width={24}
                            height={24}
                            sx={{ mr: collapsed ? 0 : 2, p: 2 }}
                        />,
                    ]}
                    to="/clients"
                    label="Clients"
                />
                <CustomLink
                    collapsed={collapsed}
                    icon={[
                        <UsersIcon width={24} height={24} sx={{ mr: collapsed ? 0 : 2, p: 2 }} />,
                        <UsersIconSolid
                            width={24}
                            height={24}
                            sx={{ mr: collapsed ? 0 : 2, p: 2 }}
                        />,
                    ]}
                    to="/users"
                    label="Users"
                />
                <CustomLink
                    collapsed={collapsed}
                    icon={[
                        <CalendarIcon
                            width={24}
                            height={24}
                            sx={{ mr: collapsed ? 0 : 2, p: 2 }}
                        />,
                        <CalendarIconSolid
                            width={24}
                            height={24}
                            sx={{ mr: collapsed ? 0 : 2, p: 2 }}
                        />,
                    ]}
                    to="/bookings"
                    label="Bookings"
                />
                <CustomLink
                    collapsed={collapsed}
                    icon={[
                        <TicketIcon width={24} height={24} sx={{ mr: collapsed ? 0 : 2, p: 2 }} />,
                        <TicketIconSolid
                            width={24}
                            height={24}
                            sx={{ mr: collapsed ? 0 : 2, p: 2 }}
                        />,
                    ]}
                    to="/tickets"
                    label="Tickets"
                />
                <CustomLink
                    collapsed={collapsed}
                    icon={[
                        <CashIcon width={24} height={24} sx={{ mr: collapsed ? 0 : 2, p: 2 }} />,
                        <CashIconSolid
                            width={24}
                            height={24}
                            sx={{ mr: collapsed ? 0 : 2, p: 2 }}
                        />,
                    ]}
                    to="/invoicing"
                    label="Invoicing"
                />
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                    }}
                >
                    <Button
                        variant="minimal"
                        onClick={toggleCollapse}
                        sx={{ mb: 3, cursor: 'pointer' }}
                    >
                        {collapsed ? (
                            <ArrowBarRight sx={{ color: 'gray.9' }} />
                        ) : (
                            <ArrowBarLeft sx={{ color: 'gray.9' }} />
                        )}
                    </Button>
                    <User collapsed={collapsed} />
                </Box>
            </Box>
        </Container>
    );
};

export default SideNav;
