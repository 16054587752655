import { Context } from '../filtersMachine';
import { fetchCategories } from '@/api/categories';

const fetchCriteriaOptions = async (ctx: Context, evt) => {
    if (evt.payload.criteria === 'Categories') {
        const response = await fetchCategories();

        return {
            ...evt,
            payload: {
                ...evt.payload,
                isNew: true,
                meta: response,
            },
        };
    }

    return evt;
};

export default fetchCriteriaOptions;
