import addFilter from './addFilter';
import updateFilter from './updateFilter';
import removeFilter from './removeFilter';
import updateContext from './updateContext';

export default {
    addFilter,
    updateFilter,
    removeFilter,
    updateContext,
    // generateParams,
};
