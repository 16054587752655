import client from '@/api/client';
import { definitions } from '@/apitypes';
import { TAKE_ITEMS } from '@/env/environment';
import { Context } from '../companiesMachine';

type CompanyQuery = definitions['CompanyQuery'];
interface FetchCompaniesParams extends Context {
    Skip?: number;
}

const fetchCompanies = (params: FetchCompaniesParams = {}) => {
    const defaultParams: CompanyQuery = {
        Active: true,
        Take: TAKE_ITEMS,
        Skip: 0,
    };

    const { Meta, Offset, ResponseStatus, Results, Total, ...desiredParams } = params;

    return client
        .get<definitions['QueryResponse_CompanyQueryResponse_']>('/superadmin/companies', {
            params: {
                ...defaultParams,
                ...desiredParams,
            },
            cache: {
                ttl: 5 * 1000 * 60,
            },
        })
        .then((res) => res.data);
};

export default fetchCompanies;
