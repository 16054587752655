/** @jsxImportSource theme-ui */
import { Badge, Button, Flex } from 'theme-ui';
import { Popover } from '@component-controls/components';
import { useState } from 'react';
import { DotsHorizontalIcon } from '@heroicons/react/outline';
import TableCell from '@/components/TableCell';
import { Company, CompanyColumnKey } from './types';
import { definitions } from '@/apitypes';
import Actions from './ActionsMenu';
import { getCompanyStatusOption } from './utils';

export interface ColumnAction {
    value: string;
    label: string;
    icon?: any;
    onClick?: (evt) => void;
}

export const createColumns = (keys: CompanyColumnKey[]) => {
    const columns = keys.map((columnKey) => {
        let width;
        if (columnKey === 'Id') {
            width = '200px';
        }
        if (columnKey === 'StatusId') {
            return {
                columnKey,
                Cell: ({ cell: { value } }) => {
                    const statusOption = getCompanyStatusOption(value);
                    return statusOption ? (
                        <Badge backgroundColor={statusOption.color}>{statusOption.value}</Badge>
                    ) : null;
                },
                Header: 'Status',
                accessor: columnKey,
                width: '200px',
            };
        }

        if (columnKey !== 'Active') {
            return {
                columnKey,
                Header: columnKey === 'LogoType' ? () => null : columnKey,
                accessor: columnKey,
                Cell: TableCell,
                width,
            };
        }

        return {
            columnKey,
            Header: columnKey,
            accessor: (row: any) => (row.Active ? 'Active' : 'Inactive'),
            width,
        };
    });

    return [
        ...columns,
        {
            Header: () => null,
            columnKey: 'Actions',
            accessor: 'Actions',
            width: '40px',
            Cell: ({ row }: { row: { original: Company } }) => {
                const [isOpen, setIsOpen] = useState(false);
                const toggleActions = () => setIsOpen(!isOpen);

                return (
                    <Flex
                        sx={{ justifyContent: 'center', position: 'relative' }}
                        onMouseLeave={() => setIsOpen(false)}
                    >
                        <Button variant="plain" onClick={toggleActions}>
                            <DotsHorizontalIcon width={16} height={16} />
                        </Button>
                        <Popover
                            arrowVisible={false}
                            placement="auto-start"
                            trigger="focus"
                            modifiers={[
                                {
                                    enabled: true,
                                    name: 'applyStyle',
                                    phase: 'write',
                                    fn({ state }) {
                                        state.styles = {
                                            popper: {
                                                ...state.styles.popper,
                                                zIndex: '20',
                                            },
                                        };
                                    },
                                },
                            ]}
                            tooltipShown={isOpen}
                            tooltip={() => <Actions {...row.original}></Actions>}
                        />
                    </Flex>
                );
            },
        },
    ];
};

export const addActionsToRow = (row: definitions['CompanyQueryResponse']) => {
    return { ...row, Actions: 'Actions' };
};
