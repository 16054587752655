/** @jsxImportSource theme-ui */
import { Text, get, Box, Grid, Alert } from 'theme-ui';
import { ComponentType, ReactPropTypes, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useActor, useMachine } from '@xstate/react';
import { Table } from '@component-controls/components';
import bookingsMachine, {
    FILTER_BOOKINGS,
    LOAD_MORE,
} from '@/containers/bookings/machines/bookings/bookingsMachine';
import { useUiService } from '@/providers/UIProvider';
import Filters from './Filters';
import SkeletonLoader from '@/components/SkeletonLoader';
import { Booking } from './types';
import { columnKeys } from './columnKeys';
import { addActionsToRow, ColumnAction, createColumns } from './columns';
import { TAKE_ITEMS } from '@/env/environment';
import { getErrorMessage } from '@/utils/helpers';
import withBookingsStatus, { BookingStatusData } from '@/hoc/withBookingsStatus';
import { ThemeUIJSX } from 'theme-ui/node_modules/@theme-ui/core';

interface TableRow extends Booking {
    Actions?: ColumnAction[];
}

interface Props extends ReactPropTypes {
    bookingsStatus: BookingStatusData;
}

const Bookings = ({ bookingsStatus }: { bookingsStatus: BookingStatusData }) => {
    const [current, send] = useMachine(bookingsMachine);
    const uiService = useUiService();
    const [uiState] = useActor(uiService);

    const collapsed = uiState.hasTag('collapsed');

    const { ref, inView } = useInView({
        initialInView: true,
        rootMargin: '500px',
        threshold: 0.01,
    });

    const { Results: results, Total: total } = current.context;
    const bookingsData = results.map((row) => addActionsToRow(row));

    const isLoading = current.matches('loading') || current.matches('filtering');
    const isError = current.matches('error');

    const columns = createColumns(columnKeys, send, bookingsStatus);

    useEffect(() => {
        window.scrollBy({ top: -1 });
        send({ type: LOAD_MORE });
    }, [inView]);

    return (
        <Grid
            gap="2"
            sx={{
                position: 'relative',
                height: 'calc(100vh - 60px)',
                gridTemplateRows: '0.1fr 1fr',
                maxWidth: '100%',
                overflowX: 'auto',
                '.thead': {
                    overflowY: 'auto',
                    overflowX: 'auto',
                },
                table: {
                    overflowX: 'auto',
                },
                td: {
                    padding: 1,
                },
            }}
        >
            <Box
                mb="3"
                sx={{
                    left: (theme) =>
                        !collapsed
                            ? `calc(${get(theme, 'sizes.navExpanded')} + 9px)`
                            : `calc(${get(theme, 'sizes.navCollapsed')} + 9px)`,
                    right: 0,
                    top: '46px',
                    zIndex: 10,
                    borderRadius: 'sm',
                }}
            >
                <Filters
                    data={bookingsData}
                    onSearch={(search) => {
                        send({ type: FILTER_BOOKINGS, payload: search });
                    }}
                ></Filters>
                <Box>
                    <Text sx={{ color: 'gray.4', fontSize: '12px' }}>{total} results found</Text>
                </Box>
            </Box>
            <Box
                sx={{
                    borderRadius: 'sm',
                    overflowY: 'scroll',
                    zIndex: 2,
                    justifySelf: 'left',
                    width: '100%',
                    backgroundColor: 'background',
                    padding: 2,
                    position: 'relative',
                }}
            >
                {!isError ? (
                    //
                    <Table<TableRow>
                        header={true}
                        sorting={false}
                        columns={columns}
                        // @ts-ignore
                        data={bookingsData}
                    />
                ) : (
                    <Box>
                        <Alert variant="danger">
                            <Text as="span" mr="1">
                                Your results couldn't be shown because of the following error:
                            </Text>
                            <Text as="span" variant="alert.danger">
                                {getErrorMessage(current.event?.data)}
                            </Text>
                        </Alert>
                    </Box>
                )}
                {isLoading ? (
                    <SkeletonLoader
                        columnCount={columnKeys.length}
                        count={
                            results.length === 0 || results.length + TAKE_ITEMS < total
                                ? TAKE_ITEMS
                                : total - results.length
                        }
                    />
                ) : null}
                <Box
                    key="infinite-loader-bottom"
                    ref={ref}
                    sx={{
                        marginTop: '-200vh',
                        height: '100vh',

                        pointerEvents: 'none',
                    }}
                />
            </Box>
        </Grid>
    );
};

// @ts-ignore
export default withBookingsStatus(Bookings);
