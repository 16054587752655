import { CompanyColumnKey } from './types';

export const columnKeys: CompanyColumnKey[] = [
    'LogoType',
    'Name',
    'OrganisationNumber',
    'StatusId',
    'Phone',
    'Email',
    'Active',
    'Id',
];
