/** @jsxImportSource theme-ui */
import { Button, ButtonProps, Spinner, Flex, Box } from 'theme-ui';

interface Props extends ButtonProps {
    isLoading?: boolean;
}

const BadgeSelect: React.FC<Props> = ({ children, isLoading, ...restProps }) => {
    return (
        <Button {...restProps}>
            <Box sx={{ position: 'relative' }}>
                <Flex sx={{ visibility: isLoading ? 'hidden' : 'visible' }}>{children}</Flex>
                {isLoading ? (
                    <Flex
                        sx={{
                            justifyContent: 'center',
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                        }}
                    >
                        <Spinner
                            variant={restProps.variant ? `styles.spinner.${restProps.variant}` : ''}
                            size={16}
                        />
                    </Flex>
                ) : null}
            </Box>
        </Button>
    );
};

export default BadgeSelect;
