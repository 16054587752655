import client from '@/api/client';
import { definitions, paths } from '@/apitypes';
import { TAKE_ITEMS } from '@/env/environment';

export const createCompanyAdministrator = (data: definitions['CreateCompanyUser']) => {
    return client
        .post<definitions['CompanyUserQueryResponse']>('/superadmin/administrator', data, {
            cache: {
                update: {
                    [`fetch-company-administrators-${data.CompanyId}`]: 'delete',
                },
            },
        })
        .then((res) => res.data);
};
