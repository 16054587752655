import localforage from 'localforage';
import { Context, FILTERS_STORAGE_KEY } from '../filtersMachine';

const persist = async (ctx: Context) => {
    const { ...ctxToPersist } = ctx;
    ctxToPersist.filters = ctxToPersist.filters.map((filter) => ({
        ...filter,
        isNew: false,
    }));
    return localforage.setItem(FILTERS_STORAGE_KEY, { ...ctxToPersist });
};

export default persist;
