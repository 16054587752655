import client from '@/api/client';
import { definitions, operations, paths } from '@/apitypes';

type PostResponse = paths['/support/cases/{Id}/attachments']['post']['responses']['200'];
type ApiParams = paths['/support/cases/{Id}/attachments']['post']['parameters']['body']['body'];

// interface Params extends ApiParams {}
type Params = Partial<ApiParams>;

const createSupportCaseAttachment = (params: Params) => {
    const defaultParams = {};

    return client
        .post<PostResponse>(`/superadmin/support/cases/${params.Id}/attachments`, {
            ...defaultParams,
            ...params,
        })
        .then((res) => res.data);
};

export default createSupportCaseAttachment;
