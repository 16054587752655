/** @jsxImportSource theme-ui */
import { Flex, Heading, Box } from 'theme-ui';
import Button from '@/components/Button';
import { useDialogState, Dialog, DialogBackdrop } from 'reakit/Dialog';

interface ConfirmatioModalProps {
    onOk?: () => void;
}

export const SuccessModal: React.FC<ConfirmatioModalProps> = ({ onOk = () => {} }) => {
    const dialog = useDialogState({ visible: true, modal: true });

    return (
        <DialogBackdrop {...dialog}>
            <Dialog
                {...dialog}
                sx={{
                    '&&': {
                        top: 'calc(50% - 100px)',
                        padding: 4,
                    },
                }}
            >
                <Heading mb="3" as="h2" color="success">
                    Company has successfully been deleted!
                </Heading>

                {/* Buttons */}
                <Flex sx={{ gap: 3, justifyContent: 'center' }}>
                    <Button variant="secondary" onClick={onOk}>
                        Ok
                    </Button>
                </Flex>
            </Dialog>
        </DialogBackdrop>
    );
};
