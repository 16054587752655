import { createMachine } from 'xstate';
import { nanoid } from 'nanoid';
import { Filter, SearchParams } from '@/containers/supportCases/types';
import actions from './actions';
import services from './services';

export interface Context extends SearchParams {
    OrderBy: string;
    filters: Filter<any>[];
}

// Actions
export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const CHANGE_ORDER_DIRECTION = 'CHANGE_ORDER_DIRECTION';

// Other constants
export const FILTERS_STORAGE_KEY = 'bm-saui-supportCases-filters';

export default createMachine<Context>({
    initial: 'initializing',
    id: 'filtersMachine',
    context: {
        filters: [
            {
                id: nanoid(),
                operator: 'ascending',
                type: 'OrderBy',
                value: 'Created',
                criteria: '',
                required: true,
            },
        ],
        OrderBy: '',
    },
    states: {
        initializing: {
            invoke: {
                src: 'initialize',
                onDone: {
                    target: 'ready',
                    actions: ['updateContext'],
                },
            },
        },
        ready: {
            entry: ['generateParams'],
            on: {
                [CHANGE_ORDER_DIRECTION]: {
                    target: ['saving'],
                    actions: ['changeOrderDirection'],
                },
                [ADD_FILTER]: {
                    target: 'addingFilter',
                },
                [REMOVE_FILTER]: {
                    target: 'saving',
                    actions: ['generateParams', 'removeFilter'],
                },
                [UPDATE_FILTER]: {
                    target: 'saving',
                    actions: ['updateFilter', 'generateParams'],
                },
            },
        },
        addingFilter: {
            invoke: {
                src: 'fetchCriteriaOptions',
                onDone: {
                    target: 'saving',
                    actions: ['addFilter', 'generateParams'],
                },
                onError: {
                    target: 'error',
                },
            },
        },
        error: {},
        saving: {
            invoke: {
                src: 'persist',
                onDone: {
                    target: 'ready',
                },
            },
        },
    },
}).withConfig({
    services,
    actions,
});
