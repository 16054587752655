/** @jsxImportSource theme-ui */
import {
    Text,
    get,
    Box,
    Grid,
    Alert,
    Divider,
    Badge,
    Heading,
    Field,
    Flex,
    Image,
    Spinner,
} from 'theme-ui';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useActor, useMachine } from '@xstate/react';
import supportCasesMachine, {
    FILTER_SUPPORTCASES,
    LOAD_MORE,
} from './machines/supportCases/supportCasesMachine';
import { useUiService } from '@/providers/UIProvider';
import Filters from './Filters';
import SkeletonLoader from '@/components/SkeletonLoader';
import { SupportCase } from './types';
import { columnKeys } from './columnKeys';
import { addActionsToRow, ColumnAction, createColumns } from './columns';
import { TAKE_ITEMS } from '@/env/environment';
import { getErrorMessage } from '@/utils/helpers';
import supportCaseMachine, {
    getIsAttachmentsError,
    getIsAttachmentsLoading,
    getIsCommentsError,
    getIsCommentsLoading,
    POST_ATTACHMENT,
    POST_MESSAGE,
    SET_CASE_ID,
} from './machines/supportCase/supportCaseMachine';
import { definitions } from '@/apitypes';
import { CompanyHeader } from '@/components/CompanyHeader';
import { Table } from '@component-controls/components';

import { UserInitials } from '../user/User';

import { Attachment } from '../../components/Attachment';
import fetchSupportCasesStatuses from '@/api/fetchSupportCasesStatuses';
import { useAuthentication } from '@/providers/AuthenticationProvider';

const CustomEditor = React.lazy(() => import('../../components/CustomQuillEditor'));

// import { CustomEditor } from '../../components/CustomQuillEditor';
interface TableRow extends SupportCase {
    Actions?: ColumnAction[];
}

const SupportCases = () => {
    const [selectedCase, _setSelectedCase] = useState<
        definitions['SupportCaseQueryResponse'] | undefined
    >();
    const [casesState, sendToCases] = useMachine(supportCasesMachine);
    const [caseState, sendToCase] = useMachine(supportCaseMachine);
    const uiService = useUiService();
    const [uiState] = useActor(uiService);
    const [caseStatuses, setCaseStatuses] =
        useState<definitions['QueryResponse_SupportCaseStatusResponse_']['Results']>();
    const { authService } = useAuthentication();

    const loggedInUser = authService.getSnapshot().context.user;

    useEffect(() => {
        fetchSupportCasesStatuses({}).then((res) => {
            setCaseStatuses(res.Results);
        });
    }, []);

    const { ref, inView } = useInView({
        initialInView: true,
        rootMargin: '500px',
        threshold: 0.01,
    });

    useEffect(() => {
        window.scrollBy({ top: -1 });
        sendToCases({ type: LOAD_MORE });
    }, [inView]);

    useEffect(() => {
        sendToCase({ type: SET_CASE_ID, payload: selectedCase });
    }, [selectedCase]);

    const setSelectedCase = (val?: definitions['SupportCaseQueryResponse']) => {
        if (selectedCase?.Id !== val?.Id) {
            _setSelectedCase(val);
        } else {
            _setSelectedCase(undefined);
        }
    };

    const collapsed = uiState.hasTag('collapsed');

    const { Results: results, Total: total } = casesState.context;

    const supportCaseData = results.map((row) => addActionsToRow(row));

    // TODO: move to getters in state machine
    const isCasesLoading = casesState.matches('loading') || casesState.matches('filtering');
    const isCasesError = casesState.matches('error');

    const isAttachmentsLoading = getIsAttachmentsLoading(caseState);
    const isAttachmentsError = getIsAttachmentsError(caseState);

    const isCommentsLoading = getIsCommentsLoading(caseState);
    const isCommentsError = getIsCommentsError(caseState);

    const columns = createColumns(columnKeys, setSelectedCase).map((column) => {
        if (column.columnKey === 'CaseStatusId') {
            return {
                ...column,
                Cell: (props) => (
                    <Flex sx={{ position: 'relative' }}>
                        {caseStatuses ? (
                            <Badge
                                backgroundColor={
                                    caseStatuses.filter(
                                        (caseStatus) => caseStatus.Id === props.value
                                    )[0].Color
                                }
                            >
                                {
                                    caseStatuses.filter(
                                        (caseStatus) => caseStatus.Id === props.value
                                    )[0].Name
                                }
                            </Badge>
                        ) : null}
                    </Flex>
                ),
            };
        }
        return column;
    });

    return (
        <Grid
            gap="2"
            sx={{
                position: 'relative',
                height: 'calc(100vh - 76px)',
                gridTemplateRows: '0.1fr 1fr',
                gridTemplateColumns: !caseState.context.case
                    ? '1fr'
                    : '0.25fr 0.25fr 0.25fr 0.25fr',
                gridTemplateAreas: `
                    "header header header header"
                    "cases cases messages messages"
                `,
                maxWidth: '100%',
                overflowX: 'auto',
                '.thead': {
                    overflowY: 'auto',
                    overflowX: 'auto',
                },
                table: {
                    overflowX: 'auto',
                },
                td: {
                    padding: 1,
                },
            }}
        >
            <Box
                mb="3"
                sx={{
                    left: (theme) =>
                        !collapsed
                            ? `calc(${get(theme, 'sizes.navExpanded')} + 9px)`
                            : `calc(${get(theme, 'sizes.navCollapsed')} + 9px)`,
                    right: 0,
                    top: '46px',
                    zIndex: 10,
                    borderRadius: 'sm',
                    gridArea: 'header',
                }}
            >
                <Filters
                    data={supportCaseData}
                    onSearch={(search) => {
                        sendToCases({ type: FILTER_SUPPORTCASES, payload: search });
                    }}
                ></Filters>
                <Box>
                    <Text sx={{ color: 'gray.4', fontSize: '12px' }}>{total} results found</Text>
                </Box>
            </Box>
            {supportCaseData.length > 0 ? (
                <Box
                    sx={{
                        borderRadius: 'sm',
                        overflowY: 'scroll',
                        zIndex: 2,
                        justifySelf: 'left',
                        width: '100%',
                        backgroundColor: 'background',
                        padding: 2,
                        position: 'relative',
                        gridArea: 'cases',
                    }}
                >
                    <Table<TableRow>
                        header={true}
                        sorting={false}
                        columns={columns}
                        // @ts-ignore
                        data={supportCaseData}
                    />
                    <Box
                        key="infinite-loader-bottom"
                        ref={ref}
                        sx={{
                            marginTop: '-200vh',
                            height: '100vh',

                            pointerEvents: 'none',
                        }}
                    />
                </Box>
            ) : null}
            {isCasesLoading ? (
                <SkeletonLoader
                    columnCount={columnKeys.length}
                    count={
                        results.length === 0 || results.length + TAKE_ITEMS < total
                            ? TAKE_ITEMS
                            : total - results.length
                    }
                />
            ) : null}
            {isCasesError ? (
                <Box>
                    <Alert variant="danger">
                        <Text as="span" mr="1">
                            Your results couldn't be shown because of the following error:
                        </Text>
                        <Text as="span" variant="alert.danger">
                            {getErrorMessage(casesState.event?.data)}
                        </Text>
                    </Alert>
                </Box>
            ) : null}
            {caseState.context.case ? (
                <Grid
                    sx={{
                        borderRadius: 'sm',
                        gridArea: 'messages',
                        background: 'background',
                        gridTemplateColumns: '1fr',
                        gridTemplateRows: 'auto auto 1fr auto',
                        gridTemplateAreas: `
                            "heading"
                            "company"
                            "messages"
                            "attachments"
                        `,
                        p: 2,
                    }}
                >
                    <Box sx={{ mb: 3, gridArea: 'heading' }}>
                        <Box>
                            <Badge p={1}>#{caseState.context.case.Id}</Badge>
                        </Box>
                        <Divider />
                        <Heading>{caseState.context.case.Title}</Heading>
                    </Box>

                    <Box sx={{ gridArea: 'company' }}>
                        {caseState.context.company ? (
                            <CompanyHeader {...caseState.context.company} />
                        ) : null}
                    </Box>

                    <Box sx={{ gridArea: 'messages' }}>
                        {isCommentsLoading ? (
                            <Spinner mb="2" size={16} />
                        ) : (
                            caseState.context.comments?.Results.map((comment) => (
                                <Flex
                                    mb="2"
                                    sx={{
                                        gap: 2,
                                    }}
                                >
                                    <UserInitials
                                        sx={{ fontWeight: 700 }}
                                        name={comment.CreatedBy}
                                        collapsed={true}
                                    />
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            borderRadius: 'sm',
                                            boxShadow:
                                                '0px 1px 2px -1px #0000001A, 0px 1px 3px 0px #0000001A',
                                            px: 2,
                                            fontFamily: 'body',
                                        }}
                                        dangerouslySetInnerHTML={{ __html: comment.Comment }}
                                    />
                                </Flex>
                            ))
                        )}
                        <Flex sx={{ gap: 2, mb: 3 }}>
                            <UserInitials
                                sx={{ fontWeight: 700 }}
                                name={loggedInUser.name}
                                collapsed={true}
                            />
                            <React.Suspense fallback={<Spinner size={16} />}>
                                <CustomEditor
                                    caseId={caseState.context.case.Id}
                                    onCommentSend={(html) =>
                                        sendToCase({
                                            type: POST_MESSAGE,
                                            payload: { Comment: html },
                                        })
                                    }
                                    onAttachmentSend={({ fileUrl }) =>
                                        sendToCase({
                                            type: POST_ATTACHMENT,
                                            payload: { FileUrl: fileUrl },
                                        })
                                    }
                                />
                            </React.Suspense>
                        </Flex>
                    </Box>

                    <Box sx={{ gridArea: 'attachments' }}>
                        {isAttachmentsLoading ? (
                            <Spinner size={16} />
                        ) : (
                            <Box>
                                <Heading>Attachments</Heading>
                                <Flex sx={{ gap: 2, flexWrap: 'wrap' }}>
                                    {caseState.context.attachments?.Results.map((attachment) => (
                                        <Attachment fileUrl={attachment.FileUrl} />
                                    ))}
                                </Flex>
                            </Box>
                        )}
                    </Box>
                </Grid>
            ) : null}
        </Grid>
    );
};

export default SupportCases;
