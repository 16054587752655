export const filters = [
    { id: 'Id', type: 'Single', format: '' },
    { id: 'Categories', type: 'Multiple', format: '' },
    { id: 'Latitude', type: 'Single', format: 'double' },
    { id: 'Longitude', type: 'Single', format: 'double' },
    { id: 'SitePath', type: 'Single', format: '' },
    { id: 'Distance', type: 'Single', format: 'double' },
    { id: 'Search', type: 'Single', format: '' },
    { id: 'CompanyOwnerId', type: 'Single', format: 'int32' },
    { id: 'Skip', type: 'Single', format: '' },
    { id: 'Take', type: 'Single', format: '' },
    { id: 'OrderBy', type: 'Single', format: '' },
    { id: 'OrderByDesc', type: 'Single', format: '' },
    { id: 'Include', type: 'Single', format: '' },
    { id: 'Fields', type: 'Single', format: '' },
];
