import { format, isSameDay } from 'date-fns';

export function formattedFromTo(
    from: Date | string,
    to: Date | string,
    includeDayIfSameDay: boolean = true
): string {
    const _from = new Date(from);
    const _to = new Date(to);

    if (isSameDay(_from, _to)) {
        if (includeDayIfSameDay) {
            return `${format(_from, 'PPp')} - ${format(_to, 'p')}`;
        } else {
            return `${format(_from, 'p')}`;
        }
    } else {
        return `${format(_from, 'PPp')} - ${format(_to, 'PPp')}`;
    }
}
