/** @jsxImportSource theme-ui */
import { Badge, Button, Flex, Spinner } from 'theme-ui';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Popover } from '@component-controls/components';
import { DotsHorizontalIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { BookingColumnKey } from './types';
import { definitions } from '@/apitypes';
import Actions from './ActionsMenu';
import { useMachine } from '@xstate/react';
import bookingMachine, {
    CANCEL_BOOKING,
    LOAD_BOOKING,
    getIsLoading,
    getIsError,
} from './machines/booking/bookingMachine';
import { CalendarMinus } from 'tabler-icons-react';
import { getErrorMessage } from '@/utils/helpers';
import { UPDATE_BOOKING } from './machines/bookings/bookingsMachine';
import { BookingStatusData } from '@/hoc/withBookingsStatus';

export interface ColumnAction {
    value?: string;
    label: string;
    icon?: React.FC;
    onClick?: () => void;
}

export const createColumns = (
    keys: BookingColumnKey[],
    sendToBookings: any,
    bookingsStatus: BookingStatusData
) => {
    const columns = keys.map((columnKey) => {
        let width;
        if (columnKey === 'Id') {
            width = '200px';
        }

        if (columnKey === 'Status') {
            return {
                columnKey,
                Header: columnKey,
                accessor: columnKey,
                width,
                Cell: ({ row: { original }, cell: { value } }) => {
                    const bookingStatus = bookingsStatus?.find(
                        (item) => item.Id === original.StatusId
                    );

                    return bookingStatus ? (
                        <Badge backgroundColor={bookingStatus.Color}>{bookingStatus.Name}</Badge>
                    ) : (
                        <Badge>{value}</Badge>
                    );
                },
            };
        }

        return {
            columnKey,
            Header: columnKey,
            accessor: columnKey,
            width,
            Cell: ({ cell }) => {
                if (cell.column.columnKey === 'From' || cell.column.columnKey === 'CreatedDate') {
                    return format(new Date(cell.value), 'd MMM yyyy, p');
                }

                return cell.value;
            },
        };
    });

    return [
        ...columns,
        {
            Header: () => null,
            columnKey: 'Actions',
            accessor: 'Actions',
            width: '40px',
            Cell: ({ row }: any) => {
                const [isOpen, setIsOpen] = useState(false);
                const toggleActions = () => setIsOpen(!isOpen);

                const [bookingState, send] = useMachine(bookingMachine);
                const isLoading = getIsLoading(bookingState);
                const isError = getIsError(bookingState);

                useEffect(() => {
                    send({ type: LOAD_BOOKING, payload: row.values });
                }, []);

                useEffect(() => {
                    if (bookingState._event.name === 'done.invoke.cancelBooking') {
                        sendToBookings({
                            type: UPDATE_BOOKING,
                            payload: bookingState._event?.data?.data,
                        });
                    }
                }, [bookingState.value]);

                const actions: ColumnAction[] = [
                    {
                        label: 'Cancel booking',
                        icon: CalendarMinus,
                        onClick: () =>
                            send({ type: CANCEL_BOOKING, payload: { id: row.values.Id } }),
                    },
                ];

                return (
                    <Flex
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            minHeight: '36px',
                        }}
                    >
                        {!isLoading && !isError ? (
                            <Flex onMouseLeave={() => setIsOpen(false)}>
                                <Button variant="plain" onClick={toggleActions}>
                                    <DotsHorizontalIcon width={16} height={16} />
                                </Button>
                                <Popover
                                    arrowVisible={false}
                                    placement="top-start"
                                    trigger="focus"
                                    modifiers={[
                                        {
                                            enabled: true,
                                            name: 'applyStyle',
                                            phase: 'write',
                                            fn({ state }) {
                                                state.styles = {
                                                    popper: {
                                                        ...state.styles.popper,
                                                        zIndex: '20',
                                                    },
                                                };
                                            },
                                        },
                                    ]}
                                    tooltipShown={isOpen}
                                    tooltip={() => <Actions {...row.original} actions={actions} />}
                                />
                            </Flex>
                        ) : isLoading ? (
                            <Spinner size={16} />
                        ) : isError ? (
                            <span title={getErrorMessage(bookingState.context.error)}>
                                <ExclamationCircleIcon
                                    width={16}
                                    height={16}
                                    sx={{ color: 'danger' }}
                                />
                            </span>
                        ) : null}
                    </Flex>
                );
            },
        },
    ];
};

export const addActionsToRow = (row: definitions['BookingQueryResponse']) => {
    return {
        ...row,
        Actions: {
            value: 'Actions',
            label: 'actions',
        },
    };
};
