/** @jsxImportSource theme-ui */
import { Flex, get, Theme } from 'theme-ui';
import React from 'react';
import { Company } from './types';
import ActionsItems from './ActionsItems';

const Actions: React.FC<Company> = (props) => {
    return (
        <Flex
            sx={{
                flexDirection: 'column',
                background: 'background',
                border: (t) => `1px solid ${get(t, 'colors.gray.2')}`,
                p: 2,
                borderRadius: 'sm',
                boxShadow: (t: Theme) => get(t, 'shadows.menu'),
            }}
        >
            <ActionsItems {...props} />
        </Flex>
    );
};

export default Actions;
