import { assign } from 'xstate';
  import { Context } from '../supportCasesMachine';
  
  type SupportCase = Context['Results'][0];
  
  const updateSupportCase = assign({
      Results: (ctx: Context, evt: { type: string; payload: SupportCase }) =>
          ctx.Results.map((res) => {
              if (evt.payload.Id === res.Id) {
                  return evt.payload;
              }
  
              return res;
          }),
  });
  
  export default updateSupportCase;