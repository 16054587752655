import client from '@/api/client';
import { definitions } from '@/apitypes';

type BookingQuery = definitions['BookingQuery'];
interface FetchBookingsParams extends BookingQuery {
    Skip?: number;
}

export const fetchBookings = (params: FetchBookingsParams = {}) => {
    const defaultParams: BookingQuery = {};

    return client
        .get<definitions['QueryResponse_BookingQueryResponse_']>('/superadmin/bookings', {
            params: {
                ...defaultParams,
                ...params,
            },
            cache: {
                ttl: 0.5 * 60 * 1000,
            },
        })
        .then((res) => res.data);
};
