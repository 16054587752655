/** @jsxImportSource theme-ui */
  import { Flex, get, Theme } from 'theme-ui';
  import React from 'react';
  import { SupportCase } from './types';
  import ActionsItems from './ActionsItems';
  
  export const Menu: React.FC = ({ children }) => (
      <Flex
          sx={{
              flexDirection: 'column',
              background: 'background',
              border: (t) => `1px solid ${get(t, 'colors.gray.2')}`,
              p: 2,
              borderRadius: 'sm',
              boxShadow: (t: Theme) => get(t, 'shadows.menu'),
          }}
      >
          {children}
      </Flex>
  );
  
  const Actions: React.FC<SupportCase> = (props: any) => {
      return (
          <Menu>
              <ActionsItems {...props} />
          </Menu>
      );
  };
  
  export default Actions;