/** @jsxImportSource theme-ui */
import styled from '@emotion/styled';
import { Flex, get } from 'theme-ui';

export const HoverCell = styled.div`
    &:hover {
        a {
            display: block;
            margin-top: -20px;
        }
    }
    a {
        display: none;
    }
`;

export const StyledFilterElement = styled.div<{ first?: boolean }>`
    display: flex;
    border-radius: ${({ theme }) => get(theme, 'radii.md')};
    align-items: center;
    justifycontent: space-evenly;
    background: ${({ theme, first }) => (!first ? get(theme, 'colors.slate.50') : undefined)};
`;

export const Box: React.FC = ({ children, ...props }) => (
    <Box sx={{ p: 2 }} {...props}>
        {children}
    </Box>
);
