import React, { useContext, useEffect } from 'react';
import { useActor, useInterpret } from '@xstate/react';
import {
    actions,
    AnyEventObject,
    BaseActionObject,
    Interpreter,
    ResolveTypegenMeta,
    ServiceMap,
    TypegenDisabled,
} from 'xstate';
import keycloak from '@/keycloak';
import authMachine, {
    KEYCLOAK_AUTH_SUCCESS,
    KEYCLOAK_AUTH_USER_INFO_SUCCESS,
    KEYCLOAK_AUTH_FAILURE,
    Context,
} from '@/machines/authMachine';
import localforage from 'localforage';

export const AUTH_STORAGE_KEY = 'bm-saui-auth';

export const REFRESH_TOKEN_INTERVAL = 55; // in seconds

type AuthService = Interpreter<
    Context,
    any,
    AnyEventObject,
    {
        value: any;
        context: Context;
    },
    ResolveTypegenMeta<TypegenDisabled, AnyEventObject, BaseActionObject, ServiceMap>
>;

export const AuthenticationContext = React.createContext({
    authService: null as null | AuthService,
    keycloak: null as null | typeof keycloak,
});

export const useAuthentication = () => useContext(AuthenticationContext);

const AuthenticationProvider: React.FC = ({ children }) => {
    const authService = useInterpret(authMachine);

    useEffect(() => {
        authService.start();
    }, []);

    useEffect(() => {
        localforage
            .getItem(AUTH_STORAGE_KEY)
            .then((tokens: { token: string; refreshToken: string } | null) => {
                keycloak
                    .init({
                        onLoad: 'login-required',
                        checkLoginIframe: false,
                        token: tokens?.token,
                        refreshToken: tokens?.refreshToken,
                        responseMode: 'query',
                    })
                    .then((val: boolean) => {
                        if (val) {
                            authService.send({
                                type: KEYCLOAK_AUTH_SUCCESS,
                                payload: {
                                    token: keycloak.token,
                                    refreshToken: keycloak.refreshToken,
                                    sessionId: keycloak.sessionId,
                                },
                            });
                            keycloak.loadUserInfo().then((userInfo: any) => {
                                authService.send({
                                    type: KEYCLOAK_AUTH_USER_INFO_SUCCESS,
                                    payload: userInfo,
                                });
                            });
                        }
                    });
            });

        keycloak.onAuthError = (error) => {
            authService.send({ type: KEYCLOAK_AUTH_FAILURE, payload: error });
        };

        keycloak.onAuthLogout = () => {
            localforage.removeItem(AUTH_STORAGE_KEY);
        };

        keycloak.onTokenExpired = () => {
            keycloak.updateToken(REFRESH_TOKEN_INTERVAL).then((val) => {
                if (val) {
                    authService.send({
                        type: KEYCLOAK_AUTH_SUCCESS,
                        payload: { token: keycloak.token, refreshToken: keycloak.refreshToken },
                    });
                }
            });
        };
    }, []);

    return (
        <AuthenticationContext.Provider value={{ authService, keycloak }}>
            {children}
        </AuthenticationContext.Provider>
    );
};

export default AuthenticationProvider;
