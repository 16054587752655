import { Box } from 'theme-ui';
import { CompanyLicense } from './types';
import { Table } from '@component-controls/components';
import { format } from 'date-fns';

export const CompanyLicensesPartial = ({
    companyLicenses,
}: {
    companyLicenses: CompanyLicense[];
}) => {
    return (
        <Box
            variant="segment"
            sx={{
                borderRadius: 'sm',
                overflowY: 'scroll',
                zIndex: 2,
                justifySelf: 'left',
                width: '100%',
                backgroundColor: 'background',
                padding: 2,
                position: 'relative',
            }}
        >
            <Table
                columns={[
                    { columnKey: 'TypeId', accessor: 'Id', Header: 'Type ID' },
                    { columnKey: 'Name', accessor: 'Type.Name', Header: 'Name' },
                    {
                        columnKey: 'Description',
                        accessor: 'Type.Description',
                        Header: 'Description',
                    },
                    {
                        columnKey: 'PeriodOfNotice',
                        accessor: 'Type.PeriodOfNoticeDays',
                        Header: 'Period of notice (days)',
                    },
                    {
                        columnKey: 'IsExtraLicense',
                        accessor: 'Type.IsExtraLicense',
                        Header: 'Is extra license',
                        Cell: ({ row }) => (row.original.Type.IsExtraLicense ? 'Yes' : 'No'),
                    },
                    {
                        columnKey: 'ValidFrom',
                        accessor: 'ValidFrom',
                        Header: 'Valid from',
                        Cell: ({ cell }) => format(new Date(cell.value), 'd MMM yyyy, p'),
                    },
                    {
                        columnKey: 'ValidTo',
                        accessor: 'ValidTo',
                        Header: 'Valid to',
                        Cell: ({ cell }) => format(new Date(cell.value), 'd MMM yyyy, p'),
                    },
                ]}
                data={companyLicenses}
            />
        </Box>
    );
};
