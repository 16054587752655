/** @jsxImportSource theme-ui */
import { Flex, Container, Box, get, Spinner, Alert } from 'theme-ui';
import React from 'react';
import Full from '@/Pages';
import { useAuthentication } from '@/providers/AuthenticationProvider';
import SideNav from '@/containers/sidenav/SideNav';
import Breadcrumbs from '@/components/Breadcrumbs';
import { useActor } from '@xstate/react';
import { ReactComponent as BokameraLogo } from '@/components/icons/logo.svg';
import CustomLink from '@/components/Link';
import { useUiService } from '@/providers/UIProvider';
import { getIsNavCollapsed } from '@/machines/uiMachine';
import { getIsAuthenticated, getIsError } from '@/machines/authMachine';
import DeleteCompanyModal from '@/containers/deleteCompanyModal/DeleteCompanyModal';

interface Props {
    Sidenav?: React.ReactElement;
    Content?: React.ReactElement;
}

const Homepage: React.FC<Props> = ({ Sidenav, Content = React.Fragment }) => {
    const uiService = useUiService();
    const [uiState] = useActor(uiService);
    // @ts-ignore
    const { authService } = useAuthentication();
    const authActor = useActor(authService as any);
    const authState: any = authActor[0];

    const collapsed = getIsNavCollapsed(uiState);
    const isAuthError = getIsError(authState);
    const isAuthenticated = getIsAuthenticated(authState);

    return isAuthenticated ? (
        <Container>
            {isAuthenticated ? <DeleteCompanyModal /> : null}
            <Box bg="background" sx={{ position: 'sticky', display: 'flex', gap: '10px' }}>
                <CustomLink
                    sx={{
                        borderRadius: 0,
                        height: '56px',
                        mb: 0,
                        minWidth: collapsed
                            ? (theme) => get(theme, 'sizes.navCollapsed')
                            : (theme) => get(theme, 'sizes.navExpanded'),
                    }}
                    collapsed={collapsed}
                    isNavLinkAware={false}
                    icon={[
                        <BokameraLogo
                            width={24}
                            height={24}
                            sx={{
                                width: '24px',
                                height: '24px',
                                p: 2,
                                borderRadius: 6,
                                fontSize: 'bold',
                            }}
                        />,
                        <BokameraLogo
                            width={24}
                            height={24}
                            sx={{
                                width: '24px',
                                height: '24px',
                                p: 2,
                                borderRadius: 6,
                                fontSize: 'bold',
                            }}
                        />,
                    ]}
                    to="/"
                    label={<strong>SuperAdmin</strong>}
                />
                <Breadcrumbs />
            </Box>

            <Flex sx={{ gap: 3 }}>
                <Flex sx={{ flexShrink: 1 }}>
                    <SideNav />
                </Flex>
                <Flex sx={{ flex: 1, flexDirection: 'column', p: 2, pl: 0, gap: 2 }}>
                    <Full />
                </Flex>
            </Flex>
        </Container>
    ) : isAuthError ? (
        <Container p={3}>
            <Alert variant="danger" bg="white" p={3}>
                There was an error while trying to log you in. Please close the tab down and try
                again.
            </Alert>
        </Container>
    ) : (
        <Container p={3}>
            <Spinner size={32} />
        </Container>
    );
};

export default Homepage;
