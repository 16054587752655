/** @jsxImportSource theme-ui */
import { Flex } from 'theme-ui';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

interface Props extends ReactDatePickerProps {
    field: {
        name: string;
        value: Date;
    };
}

const CustomDatePicker: React.FC<Props> = ({ field, ...datepickerProps }) => {
    return (
        <Flex
            sx={{
                p: 1,
                borderRadius: 'xsm',
                background: 'gray.1',
                height: '28px',
                width: '100%',

                '&&& ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected':
                    {
                        backgroundColor: 'primary',
                    },
                '.react-datepicker': {
                    color: 'gray.7',
                    borderRadius: 'sm',
                },
                '.react-datepicker__header': {
                    backgroundColor: 'gray.1',
                    borderColor: 'gray.3',
                    borderRadius: 'none',
                    borderTopLeftRadius: 'sm',
                    borderTopRightRadius: 'sm',
                },
                '.react-datepicker__day--selected': {
                    backgroundColor: 'primary',
                },
                '.react-datepicker__input-container': {
                    display: 'flex',
                    flexGrow: 1,
                    height: '100%',
                },
                '&&& .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected':
                    {
                        backgroundColor: 'blue.3',
                        color: 'white',
                    },
                '.react-datepicker__day-name:not(.react-datepicker__day--selected), .react-datepicker__day:not(.react-datepicker__day--selected), .react-datepicker__time-name:not(.react-datepicker__day--selected)':
                    {
                        color: 'gray.7',
                    },
                input: {
                    background: 'gray.1',
                    color: 'gray.7',
                    border: 'none',
                    width: '100%',
                },
            }}
        >
            <DatePicker
                selected={field.value}
                showTimeSelect
                dateFormat="MMM d, yyyy p"
                {...datepickerProps}
            />
        </Flex>
    );
};

export default CustomDatePicker;
