import { createMachine } from 'xstate';
import { CompanyLicense, LoadingState, CompanyLicensesResponse } from '../../types';
import { createLoadingState } from '../../utils';
import actions from './actions';
import services from './services';

export interface Context extends LoadingState<CompanyLicensesResponse> {}

export const initialContext: Context = createLoadingState<CompanyLicensesResponse>();

export const LOAD_COMPANY_LICENSES = 'LOAD_COMPANY_LICENSES';

export default createMachine<Context>({
    id: 'companyLicenses',
    initial: 'idle',
    context: { ...initialContext },
    states: {
        idle: {
            on: {
                [LOAD_COMPANY_LICENSES]: {
                    target: 'loading',
                },
            },
        },
        loading: {
            entry: ['assignLoadingData'],
            invoke: {
                src: 'fetchCompanyLicenses',
                onDone: {
                    target: 'loaded',
                    actions: 'assignArraySuccessData',
                },
                onError: {
                    target: 'error',
                    actions: 'assignErrorData',
                },
            },
        },
        loaded: {},
        error: {
            // entry: ['showError'],
        },
    },
}).withConfig({
    // @ts-ignore
    actions,
    // @ts-ignore
    services,
});

export const getCompanyLicenses = (state: any): CompanyLicense[] =>
    Array.isArray(state.context.data) ? state.context.data : [];

export const getError = (state: any) => (state.matches('error') ? state.context.error : null);

export const getIsLoading = (state: any) => state.matches('loading');
