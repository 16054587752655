import { assign, createMachine } from 'xstate';
import { definitions } from '@/apitypes';
import actions from './actions';
import services from './services';
import { LoadingState } from '@/containers/companyDetails/types';
import { createLoadingState } from '@/containers/companyDetails/utils';

export const CANCEL_BOOKING = 'CANCEL_BOOKING';
export const LOAD_BOOKING = 'LOAD_BOOKING';

export type Context = LoadingState<definitions['BookingQueryResponse']>;

export const initialContext = createLoadingState();
export default createMachine<Context>({
    id: 'booking',
    initial: 'idle',
    context: {
        ...initialContext,
    },
    states: {
        idle: {
            on: {
                [LOAD_BOOKING]: {
                    target: 'loaded',
                    actions: 'assignBooking',
                },
            },
        },
        loading: {
            invoke: {
                id: 'cancelBooking',
                src: 'cancelBooking',
                onDone: {
                    target: 'loaded',
                },
                onError: {
                    target: 'error',
                    actions: 'assignError',
                },
            },
        },
        loaded: {
            on: {
                [CANCEL_BOOKING]: {
                    target: 'loading',
                },
            },
        },
        error: {
            // entry: ['showError'],
        },
    },
}).withConfig({
    // @ts-ignore
    services,
    actions,
});

export const getIsLoading = (state: any) => state.matches('loading') as boolean;
export const getIsError = (state: any) => state.matches('error') as boolean;
