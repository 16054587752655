/** @jsxImportSource theme-ui */
import { Flex, Box, Image, Grid } from 'theme-ui';
import { Company } from '@/types/types';

export const CompanyDetails: React.FC<{ company: Company }> = ({ company }) => {
    return (
        <>
            <Grid
                sx={{
                    gridTemplateColumns: 'auto auto',
                }}
            >
                <Box>
                    <Flex mb="3" sx={{ gap: 2, alignItems: 'baseline', flexWrap: 'wrap' }}>
                        <Box as="h3">Company name:</Box>
                        <Box>{company.Name}</Box>
                    </Flex>
                    <Flex mb="3" sx={{ gap: 2, alignItems: 'baseline', flexWrap: 'wrap' }}>
                        <Box as="h3">Company Id:</Box>
                        <Box>{company.Id}</Box>
                    </Flex>
                    <Flex mb="3" sx={{ gap: 2, alignItems: 'baseline', flexWrap: 'wrap' }}>
                        <Box as="h3">Details:</Box>
                        <Box>{company.Details}</Box>
                    </Flex>
                    {company.OrganisationNumber ? (
                        <Flex mb="3" sx={{ gap: 2, alignItems: 'baseline', flexWrap: 'wrap' }}>
                            <Box as="h3">Org number:</Box>
                            <Box>{company.OrganisationNumber}</Box>
                        </Flex>
                    ) : null}
                </Box>
                <Box sx={{ textAlign: 'right' }}>
                    <Image
                        // @ts-ignore
                        src={company.LogoType}
                        sx={{
                            maxWidth: '1/2',
                            height: 'auto',
                        }}
                    />
                </Box>
            </Grid>
        </>
    );
};
