import localforage from 'localforage';
import { Context, FILTERS_STORAGE_KEY } from '../filtersMachine';

const initialize = async (ctx: Context) => {
    const storedCtx = await localforage.getItem(FILTERS_STORAGE_KEY);

    return storedCtx;
};

export default initialize;
