import client from '@/api/client';
import { definitions, operations, paths } from '@/apitypes';

type DeleteResponse = paths['/bookings/{Id}']['delete']['responses']['200']['schema'];
type Params = paths['/bookings/{Id}']['delete']['parameters']['query'];

export interface DeleteBookingsParams extends Params {}

const fetchBookings = (bookingId: string, params: DeleteBookingsParams = {}) => {
    const defaultParams = {};

    return client
        .delete<DeleteResponse>(`/superadmin/bookings/${bookingId}`, {
            params: {
                ...defaultParams,
                ...params,
            },
        })
        .then((res) => res.data);
};

export default fetchBookings;
