import { Flex, Button, Input, get, Box } from 'theme-ui';
import { useState, useMemo, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { XIcon, SearchIcon } from '@heroicons/react/solid';

const DEBOUNCE_TIME = 500;

interface Props {
    onSearch: (search: string) => void | Promise<void>;
}

const SearchBy: React.FC<Props> = ({ onSearch }) => {
    const [searchValue, setSearchValue] = useState('');

    const debouncedSearch = useMemo(() => debounce(onSearch, DEBOUNCE_TIME), []);

    useEffect(() => {
        debouncedSearch(searchValue);
    }, [searchValue]);

    return (
        <Flex
            sx={{
                alignItems: 'center',
                position: 'relative',
                height: '36px',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    left: 3,
                    color: 'gray.7',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <SearchIcon width={16} height={16} />
            </Box>
            <Input
                value={searchValue}
                sx={{
                    width: '100%',
                    pl: 4,
                    borderRadius: 'sm',
                    height: '100%',
                    m: 0,
                }}
                placeholder="Search by company, e-mail, phone, id..."
                onKeyDown={(evt) => {
                    if (evt.key === 'Enter') {
                        setSearchValue(evt.currentTarget.value);
                    }
                }}
                onChange={(evt) => setSearchValue(evt.currentTarget.value)}
            />
            <Button
                variant="minimal"
                onClick={() => setSearchValue('')}
                sx={{
                    position: 'absolute',
                    right: (theme) => get(theme, 'space.2'),
                }}
            >
                <XIcon width={16} height={16} />
            </Button>
        </Flex>
    );
};

export default SearchBy;
