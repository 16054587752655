import { createMachine, StateFrom } from 'xstate';

export const NAV_TOGGLE = 'NAV.TOGGLE';
export const SUBNAV_TOGGLE = 'SUBNAV.TOGGLE';

export const UI_STORAGE_KEY = 'bm-saui-ui';

const uiMachine = createMachine({
    type: 'parallel',
    states: {
        nav: {
            initial: 'expanded',
            states: {
                expanded: {
                    on: {
                        [NAV_TOGGLE]: {
                            target: 'collapsed',
                        },
                    },
                },
                collapsed: {
                    on: {
                        [NAV_TOGGLE]: {
                            target: 'expanded',
                        },
                    },
                },
            },
        },
        subNav: {
            initial: 'expanded',
            states: {
                expanded: {
                    on: {
                        [SUBNAV_TOGGLE]: {
                            target: 'collapsed',
                        },
                    },
                },
                collapsed: {
                    on: {
                        [SUBNAV_TOGGLE]: {
                            target: 'expanded',
                        },
                    },
                },
            },
        },
    },
});

export const getSubNavState = (state: any) => state.matches('subNav.expanded');
export const getNavState = (state: any) => state.matches('nav.expanded');
export const getIsNavCollapsed = (state: StateFrom<typeof uiMachine>) =>
    state.matches('nav.collapsed');
export const getIsSubNavCollapsed = (state: StateFrom<typeof uiMachine>) =>
    state.matches('subNav.collapsed');

export default uiMachine;
