import client from '@/api/client';
import { definitions, operations, paths } from '@/apitypes';

type PostResponse = paths['/support/cases/{Id}/comments']['post']['responses']['200'];
type ApiParams = paths['/support/cases/{Id}/comments']['post']['parameters']['body']['body'];

type Params = Partial<ApiParams> & {};

const createSupportCaseComment = (params: Params) => {
    const defaultParams = {};

    return client
        .post<PostResponse>(`/superadmin/support/cases/${params.Id}/comments`, {
            ...defaultParams,
            ...params,
        })
        .then((res) => res.data);
};

export default createSupportCaseComment;
