import React, { useEffect, useState } from 'react';
import uiMachine, { UI_STORAGE_KEY } from '@/machines/uiMachine';
import constate from 'constate';
import localforage from 'localforage';
import { createPersistRehidrate } from './useUiMachine';

const [UIMachineProvider, useUiService] = constate(
    createPersistRehidrate<typeof uiMachine>(UI_STORAGE_KEY, uiMachine),
    (value) => value.service
);

const UIProvider: React.FC = ({ children }) => {
    const [state, setState] = useState(undefined);

    useEffect(() => {
        localforage.getItem(UI_STORAGE_KEY).then((persistedState: any) => {
            setState(persistedState);
        });
    }, []);

    if (state === undefined) return null;

    return <UIMachineProvider state={state}>{children}</UIMachineProvider>;
};

export default UIProvider;

export { UIMachineProvider, useUiService };
