import { assign, createMachine } from 'xstate';
import { definitions } from '@/apitypes';
import actions from './actions';
import services from './services';

export const LOAD_MORE = 'LOAD_MORE';
export const FILTER_COMPANIES = 'FILTER_COMPANIES';
export const FIX_ERROR_BY_RETRYING = 'FIX_ERROR_BY_RETRYING';

type CompanyResponse = definitions['QueryResponse_CompanyQueryResponse_'];
export interface Context extends CompanyResponse {
    Skip?: number;
}

export default createMachine<Context>({
    id: 'companies',
    initial: 'idle',
    context: {
        Offset: 0,
        Total: 0,
        Results: [],
    },
    states: {
        idle: {
            on: {
                [LOAD_MORE]: {
                    target: 'loading',
                },
            },
        },
        loading: {
            on: {
                [FILTER_COMPANIES]: {
                    target: 'filtering',
                },
            },
            invoke: {
                id: 'fetchCompanies',
                src: 'fetchCompanies',
                onDone: {
                    target: 'loaded',
                    actions: ['assignCompanies'],
                },
                onError: {
                    target: 'error',
                },
            },
        },
        loaded: {
            on: {
                [LOAD_MORE]: {
                    target: 'loading',
                    cond: (ctx) => ctx.Results.length < ctx.Total,
                },
                [FILTER_COMPANIES]: {
                    target: 'filtering',
                },
            },
        },
        filtering: {
            entry: ['enterFilterCompanies'],
            on: {
                [FILTER_COMPANIES]: {
                    target: 'filtering',
                },
            },
            invoke: {
                id: 'filterCompanies',
                src: 'filterCompanies',
                onDone: {
                    target: 'loaded',
                    actions: ['filterCompanies'],
                },
                onError: {
                    target: 'error',
                },
            },
        },
        error: {
            on: {
                [FILTER_COMPANIES]: {
                    target: 'filtering',
                },
                [FIX_ERROR_BY_RETRYING]: {
                    target: 'filtering',
                },
            },
        },
    },
}).withConfig({
    actions,
    services,
});
