/** @jsxImportSource theme-ui */
import { useAuthentication } from '@/providers/AuthenticationProvider';
import { useActor } from '@xstate/react';
import { Routes, Route } from 'react-router-dom';
import Companies from '../containers/companies/Companies';
import Bookings from '../containers/bookings/Bookings';
import SupportCases from '../containers/supportCases/SupportCases';
import CompanyDetails from '../containers/companyDetails/CompanyDetails';
import { getIsAuthenticated } from '@/machines/authMachine';
import ProtectedRoute from '@/components/ProtectedRoute';

const Full: React.FC = () => {
    // @ts-ignore
    const { authService } = useAuthentication();
    const authActor = useActor(authService as any);
    const authState: any = authActor[0];
    const isAuthenticated = getIsAuthenticated(authState);

    return (
        <>
            <Routes>
                <Route
                    key="/clients"
                    path="/clients"
                    element={
                        <ProtectedRoute authenticated={isAuthenticated}>
                            <Companies />
                        </ProtectedRoute>
                    }
                />
                <Route
                    key="/clients/:id"
                    path="/clients/:id"
                    element={
                        <ProtectedRoute authenticated={isAuthenticated}>
                            <CompanyDetails />
                        </ProtectedRoute>
                    }
                />
                <Route
                    key="/bookings"
                    path="/bookings"
                    element={
                        <ProtectedRoute authenticated={isAuthenticated}>
                            <Bookings />
                        </ProtectedRoute>
                    }
                />
                <Route
                    key="/tickets"
                    path="/tickets"
                    element={
                        <ProtectedRoute authenticated={isAuthenticated}>
                            <SupportCases />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </>
    );
};

export default Full;
