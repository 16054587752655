/** @jsxImportSource theme-ui */
import { Link, Flex, Theme, get, Image, Box } from 'theme-ui';
import { Popover } from '@component-controls/components';
import { useNavigate } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { definitions } from '@/apitypes';
import { HoverCell } from './styled';
import { ColumnAction } from '@/containers/companies/columns';
import { CompanyColumnKey, CompanyColumnKeyWithActions } from '@/containers/companies/types';

const TableCell = ({
    cell: {
        value,
        row: { original: row },
    },
    column: { Header: headerName, columnKey, actions },
}: {
    cell: { value; row: { original: definitions['CompanyQueryResponse'] } };
    column: { Header: string; columnKey: CompanyColumnKeyWithActions; actions: ColumnAction[] };
}) => {
    let navigate = useNavigate();

    return (
        <HoverCell
            onClick={() => {
                navigate(row.Id);
            }}
            sx={{
                minHeight: '36px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                minWidth: columnKey === 'Id' ? '280px' : 0,
                alignItems: columnKey === 'LogoType' ? 'center' : undefined,
            }}
        >
            {columnKey === 'LogoType' ? (
                <Popover
                    arrowVisible={false}
                    placement="top-end"
                    trigger="hover"
                    modifiers={[
                        {
                            enabled: true,
                            name: 'applyStyle',
                            phase: 'write',
                            fn({ state }) {
                                state.styles = {
                                    popper: {
                                        ...state.styles.popper,
                                        zIndex: '20',
                                    },
                                };
                            },
                        },
                    ]}
                    tooltip={() =>
                        value ? (
                            <Box
                                sx={{
                                    width: 56,
                                    height: 56,
                                    backgroundImage: `url('${value}')`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    borderRadius: 'sm',
                                }}
                            />
                        ) : null
                    }
                >
                    <Box
                        sx={{
                            width: '30px',
                            height: '30px',
                            borderRadius: 'xsm',
                            backgroundImage: `url('${value}')`,
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            border: (t) => `1px solid ${get(t, 'colors.gray.2')}`,
                        }}
                    />
                </Popover>
            ) : null}

            {columnKey !== 'LogoType' ? (
                <CopyToClipboard text={value}>
                    <p>{value}</p>
                </CopyToClipboard>
            ) : null}
        </HoverCell>
    );
};

export default TableCell;
