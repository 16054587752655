import addFilter from './addFilter';
import changeOrderDirection from './changeOrder';
import updateFilter from './updateFilter';
import removeFilter from './removeFilter';
import updateContext from './updateContext';
import generateParams from './generateParams';
import updateLocation from './updateLocation';
import clearFilters from './clearFilters';
import addFilters from './addFilters';

export default {
    addFilter,
    changeOrderDirection,
    updateFilter,
    removeFilter,
    updateContext,
    generateParams,
    updateLocation,
    clearFilters,
    addFilters,
};
