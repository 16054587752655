import { createMachine, assign } from 'xstate';
import { definitions } from '@/apitypes';
import actions from './actions';
import services from './services';

export const LOAD_MORE = 'LOAD_MORE';
export const FILTER_SUPPORTCASES = 'FILTER_SUPPORTCASES';
export const UPDATE_SUPPORTCASE = 'UPDATE_SUPPORTCASE';

// export type Context = definitions['QueryResponse_Support'];
export type Context = any;

export default createMachine<any>({
    id: 'supportCases',
    initial: 'idle',
    context: {
        Offset: 0,
        Total: 0,
        Results: [],
    },
    states: {
        idle: {
            on: {
                [LOAD_MORE]: {
                    target: 'loading',
                },
            },
        },
        loading: {
            on: {
                [FILTER_SUPPORTCASES]: {
                    target: 'filtering',
                },
            },
            invoke: {
                id: 'fetchSupportCases',
                src: 'fetchSupportCases',
                onDone: {
                    target: 'loaded',
                    actions: ['assignSupportCases'],
                },
                onError: {
                    target: 'error',
                    // meta: assign({
                    //     data: (ctx, evt) => evt,
                    // }),
                },
            },
        },
        loaded: {
            on: {
                [LOAD_MORE]: {
                    target: 'loading',
                    cond: (ctx) => ctx.Results.length < ctx.Total,
                },
                [FILTER_SUPPORTCASES]: {
                    target: 'filtering',
                },
                [UPDATE_SUPPORTCASE]: {
                    actions: 'updateSupportCase',
                    target: 'loaded',
                },
            },
        },
        filtering: {
            entry: ['enterFilterSupportCases'],
            on: {
                [FILTER_SUPPORTCASES]: {
                    target: 'filtering',
                },
            },
            invoke: {
                id: 'filterSupportCases',
                src: 'filterSupportCases',
                onDone: {
                    target: 'loaded',
                    actions: ['filterSupportCases'],
                },
                onError: {
                    target: 'error',
                    // meta: assign({
                    //     data: (ctx, evt) => evt,
                    // }),
                },
            },
        },
        error: {
            // entry: ['showError'],
        },
    },
}).withConfig({
    actions,
    services,
});
