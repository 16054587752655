import keycloak from '@/keycloak';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { definitions, operations } from '@/apitypes';

export const apiService = createApi({
    reducerPath: 'bmApi',
    tagTypes: ['company'],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY,
            'x-language': 'sv',
        },
        prepareHeaders(headers, api) {
            const bearerToken = keycloak?.token;
            const sessionId = keycloak?.sessionId;

            if (bearerToken && sessionId) {
                headers.set('Authorization', `Bearer ${bearerToken}`);
                headers.set('X-Ss-Id', `${sessionId}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => {
        return {
            getCompany: builder.query<
                operations['CompanyQuery_Get']['responses']['200']['schema']['Results'][0],
                definitions['CompanyQuery']
            >({
                transformResponse: (
                    response: operations['CompanyQuery_Get']['responses']['200']['schema']
                ) => {
                    return response.Results[0];
                },
                query: (params) => ({
                    url: '/superadmin/companies',
                    method: 'get',
                    params: {
                        ...params,
                        Take: 1,
                    },
                }),
                providesTags: ['company'],
            }),
            getCompanyBookings: builder.query<
                operations['SuperAdminBookingQuerybookings_Get']['responses']['200']['schema'],
                operations['SuperAdminBookingQuerybookings_Get']['parameters']['query']
            >({
                query: (params) => ({
                    url: '/superadmin/bookings',
                    method: 'get',
                    params: {
                        CompanyBookings: true,
                        IncludeServiceInformation: true,
                        Take: 50,
                        ...params,
                    },
                }),
                providesTags: ['company'],
            }),
            getCompanyAdministrators: builder.query<
                operations['SuperAdminCompanyUserQueryadministrators_Get']['responses']['200']['schema'],
                operations['SuperAdminCompanyUserQueryadministrators_Get']['parameters']['query']
            >({
                query: (params) => ({
                    url: '/superadmin/administrators',
                    method: 'get',
                    params: {
                        Take: 50,
                        ...params,
                    },
                }),
                providesTags: ['company'],
            }),
            getCompanyLicenses: builder.query<
                operations['SuperAdminCompanyLicenseQuerylicensescompany_Get']['responses']['200']['schema']['Results'],
                operations['SuperAdminCompanyLicenseQuerylicensescompany_Get']['parameters']['query']
            >({
                query: (params) => ({
                    url: '/superadmin/licenses/company',
                    method: 'get',
                    params: {
                        Take: 50,
                        ...params,
                    },
                }),
            }),
            getCompanyInvoices: builder.query<
                operations['SuperAdminCompanyInvoiceQuerybillingcompanyinvoices_Get']['responses']['200']['schema'],
                operations['SuperAdminCompanyInvoiceQuerybillingcompanyinvoices_Get']['parameters']['query']
            >({
                query: (params) => ({
                    url: '/superadmin/billing/company/invoices',
                    method: 'get',
                    params: {
                        Take: 50,
                        ...params,
                    },
                }),
            }),
            deleteCompany: builder.mutation<
                any,
                { companyId: string; gotApprovedByAdmin?: boolean }
            >({
                query: ({ companyId, gotApprovedByAdmin }) => ({
                    url: `/superadmin/company/${companyId}`,
                    method: 'delete',
                    params: {
                        CompanyId: companyId,
                        GotApprovedByAdmin: !!gotApprovedByAdmin,
                    },
                }),
            }),
        };
    },
});

export const {
    useDeleteCompanyMutation,
    useGetCompanyQuery,
    useGetCompanyBookingsQuery,
    useGetCompanyAdministratorsQuery,
    useGetCompanyLicensesQuery,
    useGetCompanyInvoicesQuery,
} = apiService;
