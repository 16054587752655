/** @jsxImportSource theme-ui */
import { Button, Flex, Box } from 'theme-ui';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Popover } from '@component-controls/components';
import { DotsHorizontalIcon } from '@heroicons/react/outline';
import { SupportCaseColumnKey } from './types';
import { definitions } from '@/apitypes';
import Actions from './ActionsMenu';
import { SET_CASE_ID } from './machines/supportCase/supportCaseMachine';

export interface ColumnAction {
    value?: string;
    label: string;
    icon?: React.FC;
    onClick?: () => void;
}

export const createColumns = (keys: SupportCaseColumnKey[], onCellClick: (val: any) => void) => {
    const columns = keys.map((columnKey) => {
        let width;
        if (columnKey === 'Id') {
            width = '200px';
        }

        return {
            columnKey,
            Header: columnKey,
            accessor: columnKey,
            width,
            Cell: ({ cell, row }) => {
                if (
                    cell.column.columnKey === 'From' ||
                    cell.column.columnKey === 'CreatedDate' ||
                    cell.column.columnKey === 'Created'
                ) {
                    return format(new Date(cell.value), 'd MMM yyyy, p');
                }

                return <Box onClick={() => onCellClick(row.values)}>{cell.value}</Box>;
            },
        };
    });

    return [
        ...columns,
        {
            Header: () => null,
            columnKey: 'Actions',
            accessor: 'Actions',
            width: '40px',
            Cell: ({ row }: any) => {
                const [isOpen, setIsOpen] = useState(false);
                const toggleActions = () => setIsOpen(!isOpen);

                const actions: ColumnAction[] = [];

                return (
                    <Flex
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            minHeight: '36px',
                        }}
                    >
                        <Flex onMouseLeave={() => setIsOpen(false)}>
                            <Button variant="plain" onClick={toggleActions}>
                                <DotsHorizontalIcon width={16} height={16} />
                            </Button>
                            <Popover
                                arrowVisible={false}
                                placement="top-start"
                                trigger="focus"
                                modifiers={[
                                    {
                                        enabled: true,
                                        name: 'applyStyle',
                                        phase: 'write',
                                        fn({ state }) {
                                            state.styles = {
                                                popper: {
                                                    ...state.styles.popper,
                                                    zIndex: '20',
                                                },
                                            };
                                        },
                                    },
                                ]}
                                tooltipShown={isOpen}
                                tooltip={() => <Actions {...row.original} actions={actions} />}
                            />
                        </Flex>
                    </Flex>
                );
            },
        },
    ];
};

export const addActionsToRow = (row: definitions['SupportCaseQueryResponse']) => {
    return {
        ...row,
        Actions: {
            value: 'Actions',
            label: 'actions',
        },
    };
};
