import createSupportCaseAttachment from '@/api/createSupportCaseAttachment';
import { Context } from '../supportCaseMachine';

const postAttachment = async (ctx: Context, evt: any) => {
    const res = await createSupportCaseAttachment({
        Id: ctx.case.Id,
        FileUrl: evt.payload.FileUrl,
    });

    return res;
};

export default postAttachment;
