/** @jsxImportSource theme-ui */
import { Text, get, Box, Flex, Alert, Card, Spinner, Grid, Field, Button, Heading } from 'theme-ui';
import { useEffect } from 'react';
import { useMachine } from '@xstate/react';
import { useParams } from 'react-router-dom';
import companyInvoicesMachine, {
    getCompanyInvoices,
    getError,
    getIsLoading,
    LOAD_COMPANY_INVOICES,
} from './machines/company/companyInvoices';
import { CompanyInvoicesPartial } from './companyInvoicesPartial';

const CompanyInvoices = () => {
    const { id } = useParams();
    const [state, send] = useMachine(companyInvoicesMachine);
    const companyInvoices = getCompanyInvoices(state);
    const isLoading = getIsLoading(state);
    const error = getError(state);

    useEffect(() => {
        send({ type: LOAD_COMPANY_INVOICES, payload: { CompanyId: id } });
    }, [id]);

    return (
        <Box>
            <Heading>Company invoices</Heading>
            {isLoading ? <Spinner size={16} /> : null}
            {companyInvoices?.length > 0 ? (
                <CompanyInvoicesPartial companyInvoices={companyInvoices} />
            ) : null}
            {!isLoading && companyInvoices.length === 0 ? <Text>No invoices found.</Text> : null}
            {error ? (
                <Card variant="widget">
                    <Text color="danger">{error}</Text>
                </Card>
            ) : null}
        </Box>
    );
};

export default CompanyInvoices;
