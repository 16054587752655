import { assign } from 'xstate';
import { Context } from '../companiesMachine';

const enterFilterCompanies = assign<Context, any>((ctx, evt) => ({
    ...ctx,
    Offset: 0,
    Total: 0,
    Results: [],
    ...evt.payload,
}));

export default enterFilterCompanies;
