import fetchSupportCases from '@/api/fetchSupportCases';
import { TAKE_ITEMS } from '@/env/environment';

const fetchSupportCasesService = async (ctx: any, evt) => {
    const skip = ctx.Results.length === 0 && ctx.Offset === 0 ? 0 : ctx.Offset + TAKE_ITEMS;

    const { Total, Results, Offset, ...params } = ctx;
    const res = await fetchSupportCases({
        Skip: skip,
        ...params,
    });

    return res;
};

export default fetchSupportCasesService;
