import { assign } from 'xstate';
import { Context } from '../filtersMachine';

// @ts-ignore
const generateParams = assign<Context, any>((ctx, evt) => {
    return {
        // @ts-ignore
        OrderBy: ctx.filters
            .filter((filter) => filter.type === 'OrderBy')
            .reduce((acc, curr) => {
                if (curr.value) {
                    let operator = '';

                    switch (curr.operator) {
                        case 'descending':
                            operator = '-';
                            break;
                        default:
                            break;
                    }

                    acc = `${operator}${curr.value}`;
                }
                return acc;
            }, ''),
        StatusIds: ctx.filters
            .filter((filter) => filter.criteria === 'StatusIds')
            // @ts-ignore
            .flatMap((f) => f.value)
            // @ts-ignore
            .filter((f) => f.value)
            // @ts-ignore
            .flatMap((f) => f.value)
            .join(','),
        BookingStart: ctx.filters.some((f) => f.id === evt.id) ? ctx.BookingStart : '',
        BookingEnd: ctx.filters.some((f) => f.id === evt.id) ? ctx.BookingEnd : '',
        CreatedFrom: ctx.filters.some((f) => f.id === evt.id) ? ctx.CreatedFrom : '',
        CreatedTo: ctx.filters.some((f) => f.id === evt.id) ? ctx.CreatedTo : '',
        CompanyId: ctx.filters.some((f) => f.id === evt.id) ? ctx.CompanyId : '',
        ...ctx.filters
            .filter(
                (f) =>
                    f.type !== 'OrderBy' &&
                    f.type !== 'Search' &&
                    f.criteria !== 'StatusIds' &&
                    f.criteria !== 'ServiceIds'
            )
            .reduce((acc, curr) => {
                acc[curr.criteria] = curr.id === evt.id ? '' : curr.value;
                return acc;
            }, {}),
    };
});

export default generateParams;
