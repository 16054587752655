/** @jsxImportSource theme-ui */
import { NavLink, useLocation, useMatch } from 'react-router-dom';
import { Box, get, Flex, IconButton } from 'theme-ui';
import { HomeIcon } from '@heroicons/react/outline';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { ReactComponent as SlashIcon } from '@/components/icons/breadcrumbSlashIcon.svg';
import { getIconForRoute } from '@/utils/routesIcons';

interface Props {}

const Breadcrumbs: React.FC<Props> = (props) => {
    const breadcrumbs = useBreadcrumbs();
    const { pathname } = useLocation();
    const match = useMatch(pathname);

    const Icon = match ? getIconForRoute(pathname)[1] : getIconForRoute(pathname)[0];

    return (
        <Flex
            {...props}
            py="1"
            px="2"
            bg="background"
            sx={{
                alignItems: 'center',
                alignSelf: 'flex-start',
                color: 'gray.5',
                fontWeight: 500,
                fontSize: '12px',
                top: '0',
                backgroundColor: 'background',
                zIndex: 1,
                width: '100%',
                height: '56px',
            }}
        >
            {Icon ? <Icon width={16} height={16} sx={{ color: 'gray.5', mr: 2 }} /> : null}
            {breadcrumbs
                .filter((b, i) => i >= 1)
                .map(({ breadcrumb, location, key }, i) => {
                    return (
                        <Flex key={i} sx={{ alignItems: 'center' }}>
                            <Box
                                as="span"
                                sx={{
                                    visibility: i === 0 ? 'hidden' : undefined,
                                    width: i === 0 ? '0' : undefined,
                                    mr: i === 0 ? '0' : 1,
                                }}
                            >
                                <SlashIcon
                                    sx={{
                                        line: {
                                            stroke: 'gray.5',
                                        },
                                    }}
                                />
                            </Box>
                            <NavLink
                                sx={{
                                    '&, :visited, :hover, :active': {
                                        color: 'inherit',
                                    },
                                    color: (theme) => get(theme, 'gray.5'),
                                    textDecoration: 'none',
                                    fontFamily: (theme) => get(theme, 'links.default.fontFamily'),
                                    marginRight: (theme) => get(theme, 'space.1'),
                                }}
                                to={key}
                            >
                                {breadcrumb}
                            </NavLink>
                        </Flex>
                    );
                })}
        </Flex>
    );
};

export default Breadcrumbs;
