/** @jsxImportSource theme-ui */
import { Flex, get, Theme, ThemeUIStyleObject } from 'theme-ui';
import React, { ComponentProps, ComponentType, useEffect, useState } from 'react';
import { definitions } from '@/apitypes';
import fetchBookingStatuses from '@/api/bookingStatuses';

interface Props {
    [key: string]: any;
}

type BookingStatusOptionResponse = definitions['BookingStatusOptionsResponse'];

interface BookingsStatus extends BookingStatusOptionResponse {
    Icon?: string;
    Color?: string;
}

export type BookingStatusData = BookingsStatus[];

const withBookingsStatus = <T extends BookingStatusData>(
    Component: ComponentType<T>,
    params?: any
) => {
    return (props: Props) => {
        const [bookingsStatus, setBookingsStatus] = useState<BookingStatusData>();

        useEffect(() => {
            fetchBookingStatuses().then((res) => {
                setBookingsStatus(res.Results);
            });
        }, []);

        // @ts-ignore
        return <Component {...props} bookingsStatus={bookingsStatus} />;
    };
};

export default withBookingsStatus;
