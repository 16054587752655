import { ApiError } from '@/containers/companyDetails/types';
import { getErrorMessage } from '@/utils/helpers';
import { assign } from 'xstate';
import { Context } from '../companyInfo';

const assignSuccessData = assign<Context, any>((ctx, evt: ApiError) => {
    return {
        isFetching: false,
        error: getErrorMessage(evt.data),
    };
});

export default assignSuccessData;
