import { configureStore, StateFromReducersMapObject } from '@reduxjs/toolkit';
import { apiService } from '@/store/apiService';
import { deleteCompany } from '@/store/deleteCompany';

export type InitialState = StateFromReducersMapObject<typeof mainReducer>;

const mainReducer = {
    [apiService.reducerPath]: apiService.reducer,
    [deleteCompany.name]: deleteCompany.reducer,
};

export const createStore = (initialState?: InitialState) => {
    let store = configureStore({
        reducer: {
            [apiService.reducerPath]: apiService.reducer,
            [deleteCompany.name]: deleteCompany.reducer,
        },
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiService.middleware),
    });
    if (initialState) {
        store = configureStore({
            preloadedState: initialState,
            reducer: mainReducer,
            middleware: (getDefaultMiddleware) =>
                getDefaultMiddleware().concat(apiService.middleware),
        });
    }

    return store;
};
