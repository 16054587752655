/** @jsxImportSource theme-ui */
import { Flex, get, Input, Button, Box, Theme } from 'theme-ui';
import React from 'react';
import Select from '@/components/Select';
import { sortBy } from 'lodash';
import { Formik, Field } from 'formik';
import { XIcon } from '@heroicons/react/solid';
import { Filter, FilterType } from './types';
import { columnKeys } from './columnKeys';
import DropdownSelect from '@/components/DropdownSelect';
import CustomDatePicker from '@/components/CustomDatePicker';
import { getOperators } from './utils';

import 'react-datepicker/dist/react-datepicker.css';

interface Props extends Filter<FilterType> {
    onRemove: (id: string) => void;
    onUpdate: (values: Filter<any>) => void;
}

const FilterComponent: React.FC<Props> = ({
    id,
    type,
    criteria,
    operator,
    value,
    required,
    label,
    meta,
    format,
    isNew = false,
    onRemove,
    onUpdate,
}) => {
    const filterOperators = getOperators(type);

    return (
        <Formik
            enableReinitialize
            validateOnBlur={true}
            validate={(values) => {
                let errors = {};
                onUpdate({ ...values });
                return errors;
            }}
            onSubmit={(values) => {}}
            initialValues={{
                id,
                type,
                operator,
                criteria,
                value,
                label,
            }}
        >
            <Flex
                sx={{
                    alignItems: 'center',
                    gap: 1,
                    flexGrow: 1,
                    borderRadius: 'sm',
                    background: 'white',
                    p: 1,
                }}
            >
                <Field type="hidden" name="id" />
                <Box>
                    <Field
                        name="criteria"
                        children={({ field }) => (
                            <Select
                                name={field.name}
                                isDisabled={true}
                                components={{
                                    DropdownIndicator: null,
                                }}
                                defaultValue={{ label: field.value || label, value: field.value }}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    minWidth: (theme) => get(theme, 'sizes.1/5'),
                                }}
                            />
                        )}
                    />
                </Box>

                <Box>
                    <Field
                        name="operator"
                        children={({ field, form, ...props }) => (
                            <Select
                                name={field.name}
                                isDisabled={required}
                                sx={{
                                    width: 24,
                                }}
                                value={filterOperators.filter(
                                    (filter) => filter.value === field.value
                                )}
                                options={filterOperators}
                                // @ts-ignore
                                onChange={(option) => {
                                    // @ts-ignore
                                    form.setFieldValue(field.name, option.value);
                                }}
                                onBlur={() => form.setFieldTouched(field.name)}
                                // @ts-ignore
                                defaultValue={field.value}
                            />
                        )}
                    />
                </Box>
                <Box sx={{ flexGrow: 1, display: 'flex' }}>
                    <Field
                        name="value"
                        children={({ field, form, ...props }) =>
                            type === 'OrderBy' ? (
                                <Select
                                    name={field.name}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexGrow: 1,
                                    }}
                                    options={columnKeys.map((column) => ({
                                        label: column,
                                        value: column,
                                    }))}
                                    // @ts-ignore
                                    onChange={(option) => {
                                        // @ts-ignore
                                        form.setFieldValue(field.name, option.value);
                                    }}
                                    onBlur={() => form.setFieldTouched(field.name)}
                                />
                            ) : type === 'Multiple' ? (
                                <DropdownSelect
                                    name={field.name}
                                    isMulti
                                    open={isNew}
                                    hideSelectedOptions={false}
                                    closeMenuOnSelect={false}
                                    sx={{ flexGrow: 1 }}
                                    value={field.value}
                                    options={sortBy(meta?.Results).map((criteria) => ({
                                        value: criteria.Id,
                                        label: criteria.Name,
                                    }))}
                                    onChange={(value) => {
                                        form.setFieldValue(field.name, value);
                                    }}
                                />
                            ) : format === 'date-time' ? (
                                <CustomDatePicker
                                    field={{
                                        name: field.name,
                                        // @ts-ignore
                                        value: !field.value ? '' : new Date(field.value),
                                    }}
                                    // @ts-ignore
                                    onChange={(value) => {
                                        form.setFieldValue(field.name, value);
                                    }}
                                />
                            ) : type === 'Single' && !meta ? (
                                <Flex
                                    sx={{ position: 'relative', flexGrow: 1, alignItems: 'center' }}
                                >
                                    <Input
                                        sx={{
                                            borderRadius: 'xsm',
                                            p: 0,
                                            px: 2,
                                            pr: '22px',
                                            textOverflow: 'ellipsis',
                                            m: 0,
                                            height: '28px',
                                            backgroundColor: 'gray.1',
                                            '--theme-ui-input-autofill-bg': (t: Theme) =>
                                                get(t, 'colors.gray.1'),
                                        }}
                                        {...field}
                                        {...props}
                                        onChange={(val) => {
                                            form.setFieldValue(field.name, val.target.value);
                                        }}
                                        value={field.value}
                                    />
                                    <XIcon
                                        onClick={() => form.setFieldValue(field.name, '')}
                                        sx={{ position: 'absolute', right: 1, color: 'gray.7' }}
                                        width={16}
                                        height={16}
                                    />
                                </Flex>
                            ) : null
                        }
                    />
                    {!required ? (
                        <Button
                            backgroundColor={'background'}
                            color="gray.7"
                            variant="plain"
                            onClick={() => onRemove(id)}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                p: 1,
                            }}
                        >
                            <XIcon width={16} height={16} />
                        </Button>
                    ) : null}
                </Box>
            </Flex>
        </Formik>
    );
};

export default FilterComponent;
