import { Flex, Link, Text, Divider } from 'theme-ui';
import React from 'react';
import { UserAddIcon, GlobeIcon, TrashIcon } from '@heroicons/react/outline';
import { Photo } from 'tabler-icons-react';

interface Props {
    Email?: string;
    SitePath?: string;
}

const ActionsItems: React.FC<Props> = (props) => {
    return <React.Fragment></React.Fragment>;
};

export default ActionsItems;
