import { assign, createMachine, StateFrom } from 'xstate';
import { definitions } from '@/apitypes';
import actions from './actions';
import services from './services';
import { createLoadingState } from '@/containers/companyDetails/utils';
import { nanoid } from 'nanoid';

export const SET_CASE_ID = 'SET_CASE_ID';
export const POST_MESSAGE = 'POST_MESSAGE';
export const POST_ATTACHMENT = 'POST_ATTACHMENT';

export type Context = {
    case: definitions['SupportCaseQueryResponse'];
    comments: definitions['QueryResponse_SupportCaseCommentsResponse_'];
    attachments: definitions['QueryResponse_SupportCaseAttachmentResponse_'];
    company: definitions['QueryResponse_CompanyQueryResponse_'];
};

export const initialContext = createLoadingState();
const supportCaseMachine = createMachine<Context>({
    id: 'supportCase',
    type: 'parallel',
    context: {
        case: undefined,
        comments: {
            Offset: 0,
            Total: 0,
            Results: [],
        },
        attachments: {
            Offset: 0,
            Total: 0,
            Results: [],
        },
        company: null,
    },
    states: {
        comments: {
            initial: 'idle',
            states: {
                idle: {
                    on: {
                        [SET_CASE_ID]: {
                            actions: 'assignCase',
                            cond: (ctx, evt) => evt?.payload?.Id,
                            target: 'loading',
                        },
                    },
                },
                postingComment: {
                    invoke: {
                        src: 'postComment',
                        onDone: 'loading',
                        onError: 'failure',
                    },
                },
                loading: {
                    tags: ['loading'],
                    invoke: {
                        src: 'fetchComments',
                        onError: {
                            target: 'failure',
                        },
                        onDone: {
                            target: 'success',
                            actions: assign({
                                comments: (ctx, evt: any) => evt.data,
                            }),
                        },
                    },
                },
                success: {
                    on: {
                        [SET_CASE_ID]: {
                            actions: 'assignCase',
                            cond: (ctx, evt) => evt?.payload?.Id,
                            target: 'loading',
                        },
                        [SET_CASE_ID]: {
                            actions: 'assignCase',
                            cond: (ctx, evt) => !evt?.payload?.Id,
                            target: 'idle',
                        },
                        [POST_MESSAGE]: {
                            target: 'postingComment',
                            actions: assign({
                                // @ts-ignore
                                comments: (ctx, evt) => ({
                                    ...ctx.comments,
                                    Total: ctx.comments.Total + 1,
                                    Results: [
                                        ...ctx.comments.Results,
                                        {
                                            Id: nanoid(),
                                            Comment: evt.payload.Comment,
                                            Created: new Date().toISOString(),
                                            CreatedBy: 'Pontus H',
                                            SupportCaseId: ctx.case.Id,
                                        },
                                    ],
                                }),
                            }),
                        },
                    },
                },
                failure: {
                    on: {
                        [SET_CASE_ID]: {
                            actions: 'assignCase',
                            cond: (ctx, evt) => evt?.payload?.Id,
                            target: 'loading',
                        },
                        [SET_CASE_ID]: {
                            actions: 'assignCase',
                            cond: (ctx, evt) => !evt?.payload?.Id,
                            target: 'idle',
                        },
                    },
                },
            },
        },
        attachments: {
            initial: 'idle',
            states: {
                idle: {
                    on: {
                        [SET_CASE_ID]: {
                            actions: 'assignCase',
                            cond: (ctx, evt) => evt?.payload?.Id,
                            target: 'loading',
                        },
                    },
                },
                loading: {
                    tags: ['loading'],
                    invoke: {
                        src: 'fetchAttachments',
                        onError: {
                            target: 'failure',
                        },
                        onDone: {
                            target: 'success',
                            actions: assign({
                                attachments: (ctx, evt: any) => evt.data,
                            }),
                        },
                    },
                },
                success: {
                    on: {
                        [SET_CASE_ID]: {
                            actions: 'assignCase',
                            cond: (ctx, evt) => evt?.payload?.Id,
                            target: 'loading',
                        },
                        [SET_CASE_ID]: {
                            actions: 'assignCase',
                            cond: (ctx, evt) => !evt?.payload?.Id,
                            target: 'idle',
                        },
                        [POST_ATTACHMENT]: {
                            target: 'postingAttachment',
                            actions: assign({
                                // @ts-ignore
                                attachments: (ctx, evt) => ({
                                    ...ctx.attachments,
                                    Total: ctx.attachments.Total + 1,
                                    Results: [
                                        ...ctx.attachments.Results,
                                        {
                                            Id: nanoid(),
                                            FileUrl: evt.payload.FileUrl,
                                        },
                                    ],
                                }),
                            }),
                        },
                    },
                },
                postingAttachment: {
                    invoke: {
                        src: 'postAttachment',
                        onDone: 'loading',
                        onError: 'failure',
                    },
                },
                failure: {
                    on: {
                        [SET_CASE_ID]: {
                            actions: 'assignCase',
                            cond: (ctx, evt) => evt?.payload?.Id,
                            target: 'loading',
                        },
                        [SET_CASE_ID]: {
                            actions: 'assignCase',
                            cond: (ctx, evt) => !evt?.payload?.Id,
                            target: 'idle',
                        },
                    },
                },
            },
        },
        company: {},
    },
}).withConfig({
    actions,
    // @ts-ignore
    services,
});

export default supportCaseMachine;

export const getIsCommentsLoading = (state: StateFrom<typeof supportCaseMachine>) =>
    state.matches('comments.loading') && state.context.comments.Results.length === 0;

export const getIsCommentsError = (state: StateFrom<typeof supportCaseMachine>) =>
    state.matches('comments.failure');

export const getIsAttachmentsLoading = (state: StateFrom<typeof supportCaseMachine>) =>
    state.matches('attachments.loading') && state.context.attachments.Results.length === 0;

export const getIsAttachmentsError = (state: any) => state.matches('attachments.failure');
