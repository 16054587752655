import { AUTH_STORAGE_KEY } from '@/providers/AuthenticationProvider';
import localforage from 'localforage';
import { assign, createMachine, actions } from 'xstate';
import { send } from 'xstate/lib/actions';

export const KEYCLOAK_AUTH_FAILURE = 'KEYCLOAK.AUTH.FAILURE';
export const KEYCLOAK_AUTH_LOGOUT = 'KEYCLOAK.AUTH.LOGOUT';
export const KEYCLOAK_AUTH_SUCCESS = 'KEYCLOAK.AUTH.SUCCESS';
export const KEYCLOAK_AUTH_USER_INFO_SUCCESS = 'KEYCLOAK.AUTH.USER_INFO_SUCCESS';
export const AUTH_FAILURE = 'AUTH.FAILURE';

export interface Context {
    user: User;
}

interface User {
    sub: string;
    email_verified: boolean;
    name: string;
    preferred_username: string;
    given_name: string;
    locale: string;
    family_name: string;
    email: string;
}

export default createMachine<Context>({
    initial: 'unauthenticated',
    on: {
        [KEYCLOAK_AUTH_FAILURE]: {
            actions: send(
                { type: AUTH_FAILURE },
                { id: 'twoSecondsAuthFailureTimer', delay: 2000 }
            ),
        },
    },
    states: {
        unauthenticated: {
            tags: ['unauthenticated'],
            on: {
                [KEYCLOAK_AUTH_SUCCESS]: {
                    target: 'authenticated',
                    actions: [actions.cancel('twoSecondsAuthFailureTimer'), 'updateStorageTokens'],
                },
                [AUTH_FAILURE]: {
                    target: 'error',
                },
            },
        },
        authenticated: {
            tags: ['authenticated'],
            on: {
                [KEYCLOAK_AUTH_SUCCESS]: {
                    actions: [actions.cancel('twoSecondsAuthFailureTimer'), 'updateStorageTokens'],
                },
                [KEYCLOAK_AUTH_LOGOUT]: {
                    target: 'unauthenticated',
                },
                [KEYCLOAK_AUTH_USER_INFO_SUCCESS]: {
                    actions: ['addUserInfo', actions.cancel('twoSecondsAuthFailureTimer')],
                },
                [AUTH_FAILURE]: {
                    target: 'error',
                },
            },
        },
        error: {
            tags: ['error'],
        },
    },
}).withConfig({
    actions: {
        addUserInfo: assign({
            user: (ctx, evt) => evt.payload,
        }),
        updateStorageTokens: (ctx, evt) => {
            localforage.setItem(AUTH_STORAGE_KEY, {
                token: evt.payload.token,
                refreshToken: evt.payload.refreshToken,
                sessionId: evt.payload.sessionId,
            });
        },
    },
});

export const getUser = (state: Context): User | undefined => state?.user;
export const getIsAuthenticated = (state: any) => state.hasTag('authenticated');
export const getIsError = (state: any) => state.hasTag('error');
