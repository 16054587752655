// import { Context } from '../supportCasesMachine';
import fetchSupportCases from '@/api/fetchSupportCases';

const filterSupportCases = async (ctx: any, evt) => {
    const res = await fetchSupportCases({
        ...evt.payload,
    });

    return res;
};

export default filterSupportCases;
