import client from '@/api/client';
import { definitions, operations, paths } from '@/apitypes';

type GetResponse = definitions['SupportCaseQueryResponse'];
type ApiParams = operations['SupportCaseQuerycases_Get']['parameters']['query'];

interface Params extends ApiParams {}

const fetchSupportCases = (params: Params) => {
    const defaultParams = {};

    return client
        .get<GetResponse>(`/superadmin/support/cases`, {
            params: {
                ...defaultParams,
                ...params,
            },
        })
        .then((res) => res.data);
};

export default fetchSupportCases;
