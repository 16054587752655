import { assign } from 'xstate';
import { Context } from '../bookingsMachine';

type Booking = Context['Results'][0];

const updateBooking = assign({
    Results: (ctx: Context, evt: { type: string; payload: Booking }) =>
        ctx.Results.map((res) => {
            if (evt.payload.Id === res.Id) {
                return evt.payload;
            }

            return res;
        }),
});

export default updateBooking;
