import client from '@/api/client';
import { definitions } from '@/apitypes';
import { CompanyInvoicesResponse } from '@/containers/companyDetails/types';
import { Context } from '../companyAdministrators';

const fetchCompanyInvoices = (params: Context, evt) => {
    const { isFetching, data, error, ...desiredParams } = params;
    const payloadParams = evt.payload && Object.keys(evt.payload).length > 0 ? evt.payload : {};

    const defaultParams = {};

    return client
        .get<CompanyInvoicesResponse>('/superadmin/billing/company/invoices', {
            params: {
                ...defaultParams,
                ...desiredParams,
                ...payloadParams,
            },
            cache: {
                interpretHeader: true,
            },
        })
        .then((res) => res.data);
};

export default fetchCompanyInvoices;
