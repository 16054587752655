/** @jsxImportSource theme-ui */
import { Table } from '@component-controls/components';
import { format } from 'date-fns';
import { CompanyInvoice } from '@/types/types';

interface Props {
    companyInvoices: CompanyInvoice[];
}

const CompanyInvoices: React.FC<Props> = ({ companyInvoices }) => {
    return (
        <Table
            columns={[
                { columnKey: 'Id', accessor: 'Id', Header: 'Id' },
                {
                    columnKey: 'PeriodFrom',
                    accessor: 'PeriodFrom',
                    Header: 'Period from',
                    Cell: ({ row }: { row: { original: CompanyInvoice } }) =>
                        format(new Date(row.original.PeriodFrom), 'd MMM yyyy'),
                },
                {
                    columnKey: 'PeriodTo',
                    accessor: 'PeriodTo',
                    Header: 'Period to',
                    Cell: ({ row }: { row: { original: CompanyInvoice } }) =>
                        format(new Date(row.original.PeriodTo), 'd MMM yyyy'),
                },
                {
                    columnKey: 'DueDate',
                    accessor: 'DueDate',
                    Header: 'Due date',
                    Cell: ({ row }: { row: { original: CompanyInvoice } }) =>
                        format(new Date(row.original.DueDate), 'd MMM yyyy'),
                },
                {
                    columnKey: 'Last activity',
                    accessor: 'Updated',
                    Header: 'Last activity',
                    Cell: ({ row }: { row: { original: CompanyInvoice } }) =>
                        format(new Date(row.original.Updated), 'd MMM yyyy'),
                },
            ]}
            data={companyInvoices}
        />
    );
};

export default CompanyInvoices;
