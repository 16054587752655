import { assign } from 'xstate';
  import { Context } from '../supportCasesMachine';
  
  const enterFilterSupportCases = assign<Context, any>((ctx, evt) => ({
      ...ctx,
      Offset: 0,
      Total: 0,
      Results: [],
      ...evt.payload,
  }));
  
  export default enterFilterSupportCases;