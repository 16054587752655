import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const deleteCompany = createSlice({
    name: 'deleteCompany',
    initialState: {
        companyId: '',
        confirmed: false,
    },
    reducers: {
        setCompanyId: (
            state,
            action: PayloadAction<string /** @description Add two numbers */>
        ) => {
            state.companyId = action.payload;
        },
        confirm: (state, action) => {
            state.confirmed = true;
        },
    },
});

export const { setCompanyId, confirm } = deleteCompany.actions;
