import { Box } from 'theme-ui';
import React, { useState } from 'react';

interface Props {
    fileUrl: string;
}
export const Attachment: React.FC<Props> = ({ fileUrl }) => {
    const [active, setActive] = useState(false);

    const toggleActive = () => setActive(!active);

    return (
        <Box
            sx={{
                borderRadius: 'sm',
                flexBasis: active ? '100%' : '30%',
                flexGrow: active ? '1' : '0',
                height: active ? '300px' : '72px',
                backgroundImage: `url('${fileUrl}')`,
                backgroundSize: active ? 'contain' : 'cover',
                backgroundPosition: active ? 'top' : 'center',
                backgroundRepeat: 'no-repeat',
            }}
            onClick={() => toggleActive()}
        />
    );
};
