/** @jsxImportSource theme-ui */
  import { Flex, Button } from 'theme-ui';
  import { ActionObject } from 'xstate';
  import { SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/solid';
  import Select from '@/components/Select';
  import { CHANGE_ORDER_DIRECTION, UPDATE_FILTER } from './machines/filters/filtersMachine';
  import { Filter } from './types';
  import { definitions } from '@/apitypes';
  
  type SupportCaseColumnKey = keyof definitions['SupportCaseQueryResponse'];
  const sortFields: SupportCaseColumnKey[] = ['Id'];
  
  interface Props {
      filter: Filter<any>;
      onChange: (action: ActionObject<any, any>) => void;
  }
  
  enum Direction {
      ASC = 'asc',
      DESC = 'desc',
  }
  
  const OrderBy: React.FC<Props> = ({ filter, onChange }) => {
      return (
          <Flex sx={{ alignItems: 'center', background: 'background', borderRadius: 'sm' }}>
              <Button
                  title={
                      filter.operator === 'ascending'
                          ? 'Sorted by ascending order'
                          : 'Sorted by decending order'
                  }
                  backgroundColor={'background'}
                  color="gray.7"
                  variant="plain"
                  onClick={() => {
                      onChange({
                          type: CHANGE_ORDER_DIRECTION,
                      });
                  }}
                  sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '36px',
                  }}
              >
                  {filter.operator === 'ascending' ? (
                      <SortAscendingIcon sx={{ width: 4, height: 4 }} />
                  ) : (
                      <SortDescendingIcon sx={{ width: 4, height: 4 }} />
                  )}
              </Button>
              <Select
                  sx={{
                      p: 1,
                      '.react-select__control': {
                          minHeight: 'auto',
                          borderColor: 'background',
                      },
                  }}
                  value={{
                      label: `Sorted by: ${filter.value}`,
                      value: filter.value,
                  }}
                  placeholder={'SortBy'}
                  onChange={({ value }) => {
                      onChange({
                          type: UPDATE_FILTER,
                          payload: {
                              type: 'OrderBy',
                              value,
                          },
                      });
                  }}
                  options={sortFields.map((field) => ({
                      label: field,
                      value: field,
                  }))}
              />
          </Flex>
      );
  };
  
  export default OrderBy;