import { Box } from 'theme-ui';
import { CompanyAdministrator } from './types';
import { Table } from '@component-controls/components';
import { format } from 'date-fns';

export const CompanyAdministratorsPartial = ({
    companyAdministrators,
}: {
    companyAdministrators: CompanyAdministrator[];
}) => {
    return (
        <Box
            variant="segment"
            sx={{
                borderRadius: 'sm',
                overflowY: 'scroll',
                zIndex: 2,
                justifySelf: 'left',
                width: '100%',
                backgroundColor: 'background',
                padding: 2,
                position: 'relative',
            }}
        >
            <Table
                columns={[
                    { columnKey: 'Company Id', accessor: 'CompanyId', Header: 'Company Id' },
                    { columnKey: 'Email', accessor: 'Email', Header: 'Email' },
                    { columnKey: 'Phone', accessor: 'Phone', Header: 'Phone' },
                    { columnKey: 'Firstname', accessor: 'Firstname', Header: 'Firstname' },
                    { columnKey: 'Lastname', accessor: 'Lastname', Header: 'Lastname' },
                    { columnKey: 'Status', accessor: 'Active', Header: 'Status' },
                    {
                        columnKey: 'Date created',
                        accessor: 'Created',
                        Header: 'Created',
                        Cell: ({ row }: { row: { original: CompanyAdministrator } }) =>
                            format(new Date(row.original.Created), 'd MMM yyyy'),
                    },
                    {
                        columnKey: 'Last activity',
                        accessor: 'Updated',
                        Header: 'Last activity',
                        Cell: ({ row }: { row: { original: CompanyAdministrator } }) =>
                            format(new Date(row.original.Created), 'd MMM yyyy'),
                    },
                ]}
                data={companyAdministrators}
            />
        </Box>
    );
};
