import { createMachine, assign } from 'xstate';
import { definitions } from '@/apitypes';
import actions from './actions';
import services from './services';

export const LOAD_MORE = 'LOAD_MORE';
export const FILTER_BOOKINGS = 'FILTER_BOOKINGS';
export const UPDATE_BOOKING = 'UPDATE_BOOKING';

export type Context = definitions['QueryResponse_BookingQueryResponse_'];

export default createMachine<Context>({
    id: 'bookings',
    initial: 'idle',
    context: {
        Offset: 0,
        Total: 0,
        Results: [],
    },
    states: {
        idle: {
            on: {
                [LOAD_MORE]: {
                    target: 'loading',
                },
            },
        },
        loading: {
            on: {
                [FILTER_BOOKINGS]: {
                    target: 'filtering',
                },
            },
            invoke: {
                id: 'fetchBookings',
                src: 'fetchBookings',
                onDone: {
                    target: 'loaded',
                    actions: ['assignBookings'],
                },
                onError: {
                    target: 'error',
                    // meta: assign({
                    //     data: (ctx, evt) => evt,
                    // }),
                },
            },
        },
        loaded: {
            on: {
                [LOAD_MORE]: {
                    target: 'loading',
                    cond: (ctx) => ctx.Results.length < ctx.Total,
                },
                [FILTER_BOOKINGS]: {
                    target: 'filtering',
                },
                [UPDATE_BOOKING]: {
                    actions: 'updateBooking',
                    target: 'loaded',
                },
            },
        },
        filtering: {
            entry: ['enterFilterBookings'],
            on: {
                [FILTER_BOOKINGS]: {
                    target: 'filtering',
                },
            },
            invoke: {
                id: 'filterBookings',
                src: 'filterBookings',
                onDone: {
                    target: 'loaded',
                    actions: ['filterBookings'],
                },
                onError: {
                    target: 'error',
                },
            },
        },
        error: {
            on: {
                [LOAD_MORE]: {
                    target: 'loading',
                    cond: (ctx) => ctx.Results.length < ctx.Total,
                },
                [FILTER_BOOKINGS]: {
                    target: 'filtering',
                },
                [UPDATE_BOOKING]: {
                    actions: 'updateBooking',
                    target: 'loaded',
                },
            },
        },
    },
}).withConfig({
    actions,
    services,
});
