import { assign } from 'xstate';
  import { Context } from '../filtersMachine';
  
  const updateFilter = assign<Context, any>({
      filters: ({ filters }, evt) =>
          filters.map((filter) => {
              if (filter.id === evt.payload.id) {
                  return {
                      ...filter,
                      ...evt.payload,
                  };
              } else if (filter.id === evt.payload.id && evt.payload.type === filter.type) {
                  return {
                      ...filter,
                      value: evt.payload.value,
                  };
              } else if (!evt.payload.id && evt.payload.type === filter.type) {
                  return {
                      ...filter,
                      value: evt.payload.value,
                  };
              }
  
              return filter;
          }),
  });
  
  export default updateFilter;