import client from '@/api/client';
import { definitions, operations, paths } from '@/apitypes';

type GetResponse = definitions['QueryResponse_SupportCaseAttachmentResponse_'];
type ApiParams = definitions['SupportCaseAttachmentQuery'];

type Params = Partial<ApiParams> & {
    id: number;
};

const fetchSupportCasesAttachment = (params: Params) => {
    const defaultParams = {};

    const { id, ...restParams } = params;

    return client
        .get<GetResponse>(`/superadmin/support/cases/${id}/attachments`, {
            params: {
                ...defaultParams,
                ...restParams,
            },
        })
        .then((res) => res.data);
};

export default fetchSupportCasesAttachment;
