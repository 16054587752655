/** @jsxImportSource theme-ui */
import { Text, get, Box, Flex, Alert, Card, Spinner, Grid, Field, Button, Heading } from 'theme-ui';
import { useEffect } from 'react';
import { useMachine } from '@xstate/react';
import { useParams } from 'react-router-dom';
import companyAdministratorsMachine, {
    getCompanyAdministrators,
    getError,
    getIsLoading,
    LOAD_COMPANY_ADMINISTRATORS,
} from './machines/company/companyAdministrators';
import { CompanyAdministratorsPartial } from './CompanyAdministratorsPartial';

const CompanyAdministrators = () => {
    const { id } = useParams();
    const [state, send] = useMachine(companyAdministratorsMachine);
    const companyAdministrators = getCompanyAdministrators(state);
    const isLoading = getIsLoading(state);
    const error = getError(state);

    useEffect(() => {
        send({ type: LOAD_COMPANY_ADMINISTRATORS, payload: { CompanyId: id } });
    }, [id]);

    return (
        <Box>
            <Heading>Company administrators</Heading>
            {isLoading ? <Spinner size={16} /> : null}
            {companyAdministrators?.length > 0 ? (
                <CompanyAdministratorsPartial companyAdministrators={companyAdministrators} />
            ) : null}
            {error ? (
                <Card variant="widget">
                    <Text color="danger">{error}</Text>
                </Card>
            ) : null}
        </Box>
    );
};

export default CompanyAdministrators;
