import client from '@/api/client';
import { definitions, operations, paths } from '@/apitypes';

type GetResponse = definitions['QueryResponse_SupportCaseStatusResponse_'];
type ApiParams = {};

type Params = Partial<ApiParams>;

const fetchSupportCasesStatuses = (params: Params) => {
    const defaultParams = {};

    return client
        .get<GetResponse>(`/superadmin/support/cases/status`, {
            params: {
                ...defaultParams,
                ...params,
            },
        })
        .then((res) => res.data);
};

export default fetchSupportCasesStatuses;
