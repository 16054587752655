import { Link, Text, Spinner, LinkProps } from 'theme-ui';
import React, { useState } from 'react';
import { UserAddIcon } from '@heroicons/react/outline';
import { createCompanyAdministrator } from '@/api/createAdministrator';
import { KeycloakUser } from './types';
import { useAuthentication } from '@/providers/AuthenticationProvider';
import { useSelector } from '@xstate/react';
import { toast } from 'react-toastify';

export interface Props extends LinkProps {
    companyId: string;
}

export const LoginAsAdmin: React.FC<Props> = ({ companyId, ...rest }) => {
    const [inProgress, setInProgress] = useState(false);
    // @ts-ignore
    const { authService } = useAuthentication();
    // @ts-ignore
    const user: KeycloakUser = useSelector(authService, (state) => state.context.user);

    return (
        <Link
            target="_blank"
            href={process.env.REACT_APP_ADMIN_URL}
            onClick={(evt) => {
                evt.preventDefault();
                setInProgress(true);
                createCompanyAdministrator({
                    CompanyId: companyId,
                    Email: user.email,
                    Firstname: 'BokaMera',
                    Lastname: 'SuperAdmin',
                    Phone: '',
                })
                    .then(() => {
                        return new Promise((resolve) => {
                            const adminWindow = window.open(
                                `${process.env.REACT_APP_ADMIN_URL}/#/?Account=${user.email}`,
                                '_blank'
                            );

                            adminWindow.opener.postMessage('Window loaded', '*');

                            window.addEventListener(
                                'message',
                                (e) => {
                                    if (e.data === 'Window loaded') {
                                        resolve(e.data);
                                    }
                                },
                                false
                            );
                        });
                    })
                    .then(() => window.location.reload())
                    .catch((err) => {
                        toast.error(err.message, { autoClose: 5000 });
                    })
                    .finally(() => {
                        setInProgress(false);
                    });
            }}
            {...rest}
        >
            {inProgress ? <Spinner size={16} /> : null}
            <UserAddIcon width={16} height={16} />
            <Text>Login as administrator</Text>
        </Link>
    );
};
