/** @jsxImportSource theme-ui */
import { Flex, Box, Button, Text, get, Theme } from 'theme-ui';
import { OnChangeValue } from 'react-select';
import { useActor, useInterpret, useSelector } from '@xstate/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce, toArray } from 'lodash';
import { useMachine } from '@xstate/react';
import { PlusIcon } from '@heroicons/react/solid';
import filtersMachine, {
    ADD_FILTER,
    ADD_FILTERS,
    CLEAR_FILTERS,
    FILTERS_STORAGE_KEY,
    REMOVE_FILTER,
    UPDATE_FILTER,
} from './machines/filters/filtersMachine';
import { SearchParams, Criteria, Company } from './types';
import BadgeFilter from '@/components/BadgeFilter';
import SearchBy from '@/components/SearchBy';
import Select from '@/components/Select';
import Filter from './Filter';
import OrderByComponent from '@/containers/companies/OrderBy';
import { getFilterTypeByCriteria } from './utils';
import { useUiService } from '@/providers/UIProvider';
import { getSubNavState, SUBNAV_TOGGLE } from '@/machines/uiMachine';
import { createPersistRehidrate } from '@/providers/useUiMachine';
import localforage from 'localforage';
import useQuery from '@/hooks/useQuery';
import qs from 'qs';

export const selectableAddNewFilters: { label: string; value: Criteria }[] = [
    { label: 'Categories', value: 'Categories' },
    { label: 'Id', value: 'Id' },
    { label: 'SitePath', value: 'SitePath' },
    { label: 'Email', value: 'Email' },
];

interface Props {
    data: Company[];
    onSearch: (search: SearchParams) => void | Promise<void>;
    disabled?: boolean;
}

const Filters: React.FC<Props> = ({ onSearch }) => {
    const { queryParams } = useQuery();

    const service = useInterpret(
        filtersMachine,

        {
            state: localStorage.getItem(FILTERS_STORAGE_KEY)
                ? JSON.parse(localStorage.getItem(FILTERS_STORAGE_KEY))
                : null,
            // devTools: true,
        },
        ({
            machine,
            configuration,
            toStrings,
            toJSON,
            matches,
            can,
            hasTag,
            history,
            transitions,
            ...restState
        }) => localStorage.setItem(FILTERS_STORAGE_KEY, JSON.stringify(restState))
    );
    const [state, send] = useActor(service);

    const uiService = useUiService();
    const isSubnavExpanded = useSelector(uiService, getSubNavState);

    const {
        context: { filters, Search, OrderBy, Categories, Id, IsEmail },
    } = state;

    const debouncedSearch = useCallback(
        debounce(
            ({ Search, OrderBy, Categories, Id, IsEmail }) =>
                onSearch({ Search, OrderBy, Categories, Id, IsEmail }),
            200
        ),
        []
    );

    useEffect(() => {
        let obj = {};
        filters.forEach((filter) => {
            // TODO: when api for operators is ready enable this
            // obj[`${filter.operator}${filter.criteria}`] = filter.value;
            obj[`${filter.criteria}`] = filter.value;
        });

        debouncedSearch({ Search, OrderBy, Categories, Id, IsEmail, ...obj });
    }, [Search, OrderBy, Categories, Id, IsEmail, filters]);

    // Because filters from the URL have precedence
    useEffect(() => {
        if (queryParams.filters) {
            send({ type: CLEAR_FILTERS });
            localStorage.removeItem(FILTERS_STORAGE_KEY);
            const filters = toArray(qs.parse(queryParams.filters, { ignoreQueryPrefix: true }));
            send({ type: ADD_FILTERS, payload: filters });
        }
    }, []);

    return (
        <Flex sx={{ flexDirection: 'column', gap: '2', mb: 2 }}>
            <Flex sx={{ flex: 1, gap: 2 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <SearchBy
                        onSearch={(search) => {
                            send({
                                type: UPDATE_FILTER,
                                payload: {
                                    type: 'Search',
                                    value: search,
                                },
                            });
                        }}
                    />
                </Box>

                {filters.filter((f) => f.type === 'OrderBy').find((f) => f) ? (
                    <OrderByComponent
                        // @ts-ignore
                        filter={filters.filter((f) => f.type === 'OrderBy').find((f) => f)}
                        onChange={send}
                    />
                ) : null}

                <Box>
                    <BadgeFilter
                        opened={isSubnavExpanded}
                        onToggle={() => uiService.send(SUBNAV_TOGGLE)}
                        count={
                            filters
                                .filter((f) => !f.required)
                                .filter((f) => f.type !== 'Search' && f.type !== 'OrderBy').length
                        }
                    />
                </Box>
            </Flex>
            <Flex
                sx={{
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: '2',
                    opacity: isSubnavExpanded ? 1 : 0,
                    maxHeight: isSubnavExpanded ? 'initial' : 0,
                }}
            >
                {filters
                    .filter((f) => f.type !== 'Search' && f.type !== 'OrderBy')
                    .map((filter, i) => {
                        return (
                            <Flex
                                sx={{
                                    // sizes.24 is used to reserve space for add new button
                                    flexBasis: (t: Theme) => [
                                        '100%',
                                        '100%',
                                        '100%',
                                        `calc(50% - ${get(t, 'sizes.2')})`,
                                    ],
                                    borderRadius: 'md',
                                    alignItems: 'center',
                                    justifyContent: 'space-evenly',
                                    flexGrow: 1,
                                    maxWidth: (t: Theme) => ['100%', '100%', '100%', '50%'],
                                }}
                                key={filter.id}
                            >
                                {/* @ts-ignore */}
                                <Filter
                                    {...filter}
                                    onRemove={(id) => {
                                        send({ type: REMOVE_FILTER, payload: { id } });
                                    }}
                                    onUpdate={(values) =>
                                        send({
                                            type: UPDATE_FILTER,
                                            payload: values,
                                        })
                                    }
                                />
                            </Flex>
                        );
                    })}

                <Flex>
                    <Select
                        components={{
                            DropdownIndicator: () => (
                                <Button
                                    variant="plain"
                                    sx={{
                                        height: '36px',
                                    }}
                                >
                                    <PlusIcon sx={{ width: '8px', height: '8px' }} />
                                    <Text>Add new</Text>
                                </Button>
                            ),
                            Placeholder: () => null,
                            SingleValue: () => null,
                            IndicatorSeparator: () => null,
                        }}
                        isSearchable={false}
                        sx={{
                            '.react-select__value-container': {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 0,
                                height: 'auto',
                            },
                            '.react-select__control': {
                                minWidth: 'auto',
                                height: 'auto',
                            },
                        }}
                        options={selectableAddNewFilters.map((filter) => ({
                            value: filter.value,
                            label: filter.label,
                        }))}
                        onChange={(
                            option: OnChangeValue<{ label: Criteria; value: Criteria }, false>
                        ) => {
                            send({
                                type: ADD_FILTER,
                                payload: {
                                    label: option.label,
                                    criteria: option.value,
                                    type: getFilterTypeByCriteria(option.value),
                                },
                            });
                        }}
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};

export default Filters;
