import client from '@/api/client';
import { definitions } from '@/apitypes';

type BookingStatusQuery = definitions['BookingStatusQuery'];
interface FetchBookingStatusesParams extends BookingStatusQuery {
    Skip?: number;
}

const fetchBookingStatuses = (params: FetchBookingStatusesParams = {}) => {
    const defaultParams: BookingStatusQuery = {};

    return client
        .get<definitions['QueryResponse_BookingStatusQueryResponse_']>('/bookings/status', {
            params: {
                ...defaultParams,
                ...params,
            },
        })
        .then((res) => res.data);
};

export default fetchBookingStatuses;
