import { TAKE_ITEMS } from '@/env/environment';
import { assign } from 'xstate';
import { Context } from '../companiesMachine';

const assignCompanies = assign<Context, any>((ctx, evt) => ({
    ...evt.data,
    Skip: evt.data.Offset + TAKE_ITEMS,
    Results: [...ctx.Results, ...evt.data.Results],
}));

export default assignCompanies;
