import { Box } from 'theme-ui';
import { CompanyInvoice } from './types';
import { Table } from '@component-controls/components';
import { format } from 'date-fns';

export const CompanyInvoicesPartial = ({
    companyInvoices,
}: {
    companyInvoices: CompanyInvoice[];
}) => {
    return (
        <Box
            variant="segment"
            sx={{
                borderRadius: 'sm',
                overflowY: 'scroll',
                zIndex: 2,
                justifySelf: 'left',
                width: '100%',
                backgroundColor: 'background',
                padding: 2,
                position: 'relative',
            }}
        >
            <Table
                columns={[
                    { columnKey: 'Id', accessor: 'Id', Header: 'Id' },
                    {
                        columnKey: 'PeriodFrom',
                        accessor: 'PeriodFrom',
                        Header: 'Period from',
                        Cell: ({ row }: { row: { original: CompanyInvoice } }) =>
                            format(new Date(row.original.PeriodFrom), 'd MMM yyyy'),
                    },
                    {
                        columnKey: 'PeriodTo',
                        accessor: 'PeriodTo',
                        Header: 'Period to',
                        Cell: ({ row }: { row: { original: CompanyInvoice } }) =>
                            format(new Date(row.original.PeriodTo), 'd MMM yyyy'),
                    },
                    {
                        columnKey: 'DueDate',
                        accessor: 'DueDate',
                        Header: 'Due date',
                        Cell: ({ row }: { row: { original: CompanyInvoice } }) =>
                            format(new Date(row.original.DueDate), 'd MMM yyyy'),
                    },
                    {
                        columnKey: 'Last activity',
                        accessor: 'Updated',
                        Header: 'Last activity',
                        Cell: ({ row }: { row: { original: CompanyInvoice } }) =>
                            format(new Date(row.original.Updated), 'd MMM yyyy'),
                    },
                ]}
                data={companyInvoices}
            />
        </Box>
    );
};
