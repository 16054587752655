import createSupportCaseComment from '@/api/createSupportCaseComment';
import { Context } from '../supportCaseMachine';

const postComment = async (ctx: Context, evt: any) => {
    const res = await createSupportCaseComment({ Id: ctx.case.Id, Comment: evt?.payload?.Comment });

    return res;
};

export default postComment;
