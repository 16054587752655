import { Criteria, Filter } from '@/containers/companies/types';
import { capitalize } from 'lodash';
import { assign } from 'xstate';
import { Context } from '../filtersMachine';

// @ts-ignore
const generateParams = assign<Context, any>((ctx, evt) => {
    return {
        OrderBy: ctx.filters
            .filter((filter) => filter.type === 'OrderBy')
            .reduce((acc, curr) => {
                if (curr.value) {
                    let operator = '';

                    switch (curr.operator) {
                        case 'descending':
                            operator = '-';
                            break;
                        default:
                            break;
                    }

                    acc = `${operator}${curr.value}`;
                }
                return acc;
            }, ''),
        // @ts-ignore
        Search: ctx.filters
            .filter((filter) => filter.type === 'Search')
            .reduce((acc, curr) => curr.value, ''),
        // @ts-ignore
        Categories: ctx.filters
            .filter((filter) => filter.criteria === 'Categories')
            // @ts-ignore
            .flatMap((f) => f.value)
            // @ts-ignore
            .filter((f) => f.value)
            // @ts-ignore
            .flatMap((f) => f.value)
            .join(','),
        ...generateParam(ctx.filters, 'Id'),
        ...generateParam(ctx.filters, 'Email'),
        ...generateParam(ctx.filters, 'SitePath'),
    };
});

const generateParam = (filters: Filter<any>[], criteria: Criteria) => {
    const paramValue = filters
        .filter((filter) => filter.criteria === criteria)
        .flatMap((f) => (typeof f.value === 'string' ? f.value : ''))[0];
    const paramOperator = filters
        .filter((filter) => filter.criteria === criteria)
        .flatMap((f) => f.operator)[0];

    if (!paramOperator || !criteria) {
        return {};
    }

    let inverseOperator;
    if (paramOperator === 'Is') {
        inverseOperator = 'IsNot';
    } else {
        inverseOperator = 'Is';
    }

    const fieldName = `${paramOperator}${capitalize(criteria)}`;
    const inverseFieldName = `${inverseOperator}${capitalize(criteria)}`;

    return {
        ...{ [fieldName]: paramValue },
        ...{ [inverseFieldName]: '' },
    };
};

export default generateParams;
