import { createMachine } from 'xstate';
import { definitions } from '@/apitypes';
import { Company, LoadingState } from '../../types';
import { createLoadingState } from '../../utils';
import actions from './actions';
import services from './services';

export interface Context extends LoadingState<definitions['CompanyQueryResponse']> {}

export const initialContext: Context = createLoadingState<definitions['CompanyQueryResponse']>();

export const LOAD_COMPANY = 'LOAD_COMPANY';

export default createMachine<Context>({
    id: 'company',
    initial: 'idle',
    context: { ...initialContext },
    states: {
        idle: {
            on: {
                [LOAD_COMPANY]: {
                    target: 'loading',
                },
            },
        },
        loading: {
            entry: ['assignLoadingData'],
            invoke: {
                src: 'fetchCompany',
                onDone: {
                    target: 'loaded',
                    actions: 'assignSuccessData',
                },
                onError: {
                    target: 'error',
                    actions: 'setErrorData',
                },
            },
        },
        loaded: {},
        error: {
            // entry: ['showError'],
        },
    },
}).withConfig({
    actions,
    // @ts-ignore
    services,
});

export const getCompany = (state): Company => state.context.data;

export const getError = (state: any) => (state.matches('error') ? state.context.error : null);

export const getIsLoading = (state: any): boolean => state.matches('loading');
